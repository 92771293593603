import * as React from "react";
import type { AccordionProps } from "./Accordion";
import { Accordion } from "./Accordion";
import Collapse from "@mui/material/Collapse";
import {
  getHistoryStateValue,
  replaceHistoryState,
} from "../../../utils/History";
import { List, ShowMore } from "./AccordionList.styled";
import stringFormat from "string-format";
import { useMediaQuery } from "@mui/material";
import { mobileMediaQuery } from "../../../utils/xxl-screen";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";

type AccordionListProps = AccordionProps & {
  children: React.ReactElement[];
  numberOfVisibleListItems: number;
  showAllButtonText?: string;
};

export const AccordionList: React.FunctionComponent<AccordionListProps> = ({
  children,
  numberOfVisibleListItems,
  showAllButtonText,
  expanded,
  ...props
}) => {
  const { t } = useTranslations();
  const buttonText =
    showAllButtonText === undefined
      ? t("general.show.more")
      : showAllButtonText;
  const expandedFilterAccordions = getHistoryStateValue(
    "expandedFilterAccordions"
  );
  const initiallyShowAll =
    expandedFilterAccordions?.includes(props.id) ?? false;
  const [showAllListItems, setShowAllListItems] =
    React.useState(initiallyShowAll);
  const createItems = (item: React.ReactElement): React.ReactElement => (
    <li key={item.key}>{item}</li>
  );
  const visibleListItems = children
    .slice(0, numberOfVisibleListItems)
    .map(createItems);

  const hiddenListItems = children
    .slice(numberOfVisibleListItems)
    .map(createItems);

  const handleClickShowAll = (): void => {
    const expandedFilterAccordions = new Set(
      getHistoryStateValue("expandedFilterAccordions")
    );
    expandedFilterAccordions[!showAllListItems ? "add" : "delete"](props.id);
    replaceHistoryState({
      expandedFilterAccordions: Array.from(expandedFilterAccordions),
    });
    setShowAllListItems(!showAllListItems);
  };
  const shouldShowMoreButtonBeVisible =
    children.length > numberOfVisibleListItems;

  const isMobile = useMediaQuery(mobileMediaQuery);
  return (
    <Accordion {...props} expanded={expanded}>
      <List>{visibleListItems}</List>
      <Collapse in={showAllListItems}>
        <List>{hiddenListItems}</List>
      </Collapse>
      <Collapse in={shouldShowMoreButtonBeVisible}>
        <ShowMore
          onClick={handleClickShowAll}
          isMobile={isMobile}
          data-testid={`${props.id}-show-all-btn`}
          className={
            isMobile
              ? ""
              : "button button--secondary button--outlined button--full-width button--small"
          }
        >
          {showAllListItems
            ? t("general.show.less")
            : stringFormat(buttonText, hiddenListItems.length.toString())}
        </ShowMore>
      </Collapse>
    </Accordion>
  );
};
