import { FilterIcon } from "@xxl/icons";
import { AllFiltersStyled } from "./FilterBar.styled";
import { useFilterContext, TOGGLE_FILTER_DRAWER } from "../FilterState";

type FilterItemProps = {
  name: string;
  className?: string;
};

export const FilterAllFilters = ({ name, className }: FilterItemProps) => {
  const { state, dispatch } = useFilterContext();
  const { isFilterDrawerOpen } = state;

  const openFilterDrawer = () => {
    dispatch({
      type: TOGGLE_FILTER_DRAWER,
      payload: true,
    });
  };

  return (
    <AllFiltersStyled
      onClick={openFilterDrawer}
      className={className}
      inline={true}
      isExpanded={isFilterDrawerOpen}
    >
      <FilterIcon />
      <span>{name} </span>
    </AllFiltersStyled>
  );
};
