/* TODO: Remove in XD-13966 */
import * as React from "react";
import { REMOVE_ALL_FILTERS } from "../../Search/Constants";
import { useSearchContext } from "../../Search/SearchState";
import { getSelectedFiltersCount } from "../FilterHelper";
import { RemoveAllFilters } from "./RemoveAllFilters";

export const LegacyRemoveAllFilters = ({
  variant,
}: {
  variant: "button" | "link";
}) => {
  const {
    dispatch,
    state: { selectedFilters },
  } = useSearchContext();

  const handleClick = () => dispatch({ type: REMOVE_ALL_FILTERS });

  return (
    <RemoveAllFilters
      numberOfSelectedFilters={getSelectedFiltersCount(selectedFilters)}
      variant={variant}
      onclick={handleClick}
    />
  );
};
