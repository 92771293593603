export default {
  smallTitle: {
    fontSize: 20,
    lineHeight: 1.1,
  },
  bigTitle: {
    fontSize: 34,
    lineHeight: 1,
  },
  link: {
    fontSize: 12,
    lineHight: 1.5,
    fontFamily: "var(--font-family-medium)",
    tablet: {
      fontSize: 15,
      lineHight: 1.2,
    },
  },
};
