import styled from "@emotion/styled/macro";
import React from "react";
import { PageType } from "../../constants";
import { useSharedData } from "../../contexts/SharedData";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { interactionFeedback, typographyToCss } from "../../styles/helpers";
import { xxlTheme } from "../../styles/xxl-theme";

const { typography } = xxlTheme;

const ResetSearchResultForm = styled.form`
  width: 100%;
  text-align: center;
`;

const ResetSearchResultButton = styled.button`
  ${typographyToCss(typography.bigRegular)};
  cursor: pointer;
  border: none;
  background: none;
  text-decoration: underline;
  ${interactionFeedback("text-decoration: none;")};
`;

export const SearchQueryResetButton: React.FunctionComponent = () => {
  const { t } = useTranslations();
  const {
    data: { requestMapping, pageType },
  } = useSharedData();

  const isSearchPage = pageType === PageType.SEARCH;

  return isSearchPage ? (
    <ResetSearchResultForm
      action={requestMapping.search}
      autoComplete="off"
      data-testid="search-auto-suggest"
    >
      <ResetSearchResultButton type="submit">
        {t("search.page.reset.query")}
      </ResetSearchResultButton>
    </ResetSearchResultForm>
  ) : null;
};
