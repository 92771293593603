import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { xxlTheme } from "../../../styles/xxl-theme";
import { typographyToCss } from "../../../styles/helpers";

const { colors, spaces } = xxlTheme;

export const Accordion = styled(MuiAccordion)(() => ({
  boxShadow: "none",
  backgroundColor: "transparent",
  "&.Mui-expanded": {
    marginTop: 0,
  },
  "& :before": {
    backgroundColor: "transparent",
  },
}));

export const AccordionSummary = styled(MuiAccordionSummary)(() => ({
  borderBottom: `solid 1px ${colors.xxlMediumGrey}`,
  margin: 0,
  padding: `${spaces.large} 0 7px`,
  minHeight: 0,
  "&.Mui-expanded": {
    minHeight: 0,
    marginTop: 0,
  },
  alignItems: "flex-end",

  "& .MuiAccordionSummary-content": {
    margin: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  "& .Mui-expanded": {
    marginTop: 0,
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    position: "relative",
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  flexDirection: "column",
  padding: 0,
}));

export const AccordionHeading = styled("span")`
  ${typographyToCss({
    fontSize: 15,
    lineHeight: 1,
    letterSpacing: -0.1,
    fontFamily: "var(--font-family-regular)",
    fontWeight: 100,
  })}
  margin: 0;

  &.active {
    font-weight: bold;
  }
`;

export const ExpandIconStyled = styled(ExpandMoreIcon)(() => ({
  height: "12px",
  width: "12px",
}));
