import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import { hasNoValue, hasValue, isNotNull } from "@xxl/common-utils";
import type { LongTailData } from "@xxl/frontend-api";
import type { FacetData, SortOrderData } from "@xxl/product-search-api";
import noop from "lodash/noop";
import React, { forwardRef, useMemo } from "react";
import type { RangeValue } from "react-app/src/components/Common/RangeSlider/RangeSlider.types";
import { UrlPath } from "react-app/src/components/Search/UrlPath";
import { useSharedData } from "react-app/src/contexts/SharedData";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import {
  laptopMediaQuery,
  mobileAndTabletNotHorizontalMediaQuery,
  mobileMediaQuery,
} from "../../../utils/xxl-screen";
import { closeDropdownFilter } from "../../Filter/FilterHelper";
import type { OnChangeFilterProps } from "../../Filter/FilterMenu/Filters/FilterAccordions/DistinctFilterAccordion/types";
import { useFilterContext } from "../../Filter/FilterState";
import {
  LegacyRemoveAllFilters,
  RemoveAllFilters,
} from "../../Filter/RemoveAllFilters";
import { FilterDrawer } from "../FilterDrawer/FilterDrawer";
import { LegacyFilterDrawer } from "../FilterDrawer/LegacyFilterDrawer";
import { LegacyFilters } from "../FilterMenu/Filters";
import { MAX_NUMBER_OF_FILTERS } from "../FilterMenu/Filters/constants";
import { DistinctFilterAccordion } from "../FilterMenu/Filters/FilterAccordions/DistinctFilterAccordion";
import { RangeFilterAccordion } from "../FilterMenu/Filters/FilterAccordions/RangeFilterAccordion";
import { RANGE_FILTER_MAX, RANGE_FILTER_MIN } from "./constants";
import { FilterAllFilters } from "./FilterAllFilters";
import { FilterArticleCount } from "./FilterArticleCount";
import {
  FilterBarStyled,
  FilterBarWrapperStyled,
  FilterItemsStyled,
  MobileFilterBarHeaderStyled,
} from "./FilterBar.styled";
import { FilterGridSwitch } from "./FilterGridSwitch";
import { FilterSortItems } from "./FilterSortItems";
import { LegacyFilterSortItems } from "./LegacyFilterSortItems";

type FilterBarProps = {
  facets: FacetData[] | null;
  longTailFacets: LongTailData[] | null;
  longTailPattern: string | null;
  selectedColumnsNumber: number;

  selectedFilters: {
    [key: string]: (string | number)[];
  } | null;
  selectedSort: SortOrderData["selected"] | null;
  sortOptions: SortOrderData["options"] | null;
  totalHits: number;
  isStickyFilter?: boolean;
  onChangeFilter?: ({ action, id, values }: OnChangeFilterProps) => void;
  onChangeRangeFilter?: (id: string) => (value: RangeValue) => void;
  onChangeSortOption?: (value: string) => void;
  shouldShowFilterGridSwitch?: boolean;
  shouldAutomaticallyScrollToFilterBar?: boolean;
};

const FilterBar = forwardRef<HTMLDivElement, FilterBarProps>(
  (
    {
      facets,
      isStickyFilter = false,
      longTailFacets,
      longTailPattern,
      onChangeFilter = noop,
      onChangeRangeFilter,
      onChangeSortOption = noop,
      shouldAutomaticallyScrollToFilterBar = true,
      shouldShowFilterGridSwitch = true,
      selectedColumnsNumber,
      selectedFilters,
      selectedSort,
      sortOptions,
      totalHits,
    },
    ref
  ) => {
    const {
      data: {
        configuration: {
          frontendApi: { basePath },
        },
      },
    } = useSharedData();

    const { t } = useTranslations();
    const isMobile = useMediaQuery(mobileMediaQuery);
    const isMobileOrTablet = useMediaQuery(
      mobileAndTabletNotHorizontalMediaQuery
    );
    const isLaptop = useMediaQuery(laptopMediaQuery);
    const { state, dispatch } = useFilterContext();
    const { filterSelected, expandedTopFilter, isStickyFilterBar } = state;
    const fiterDropdownExpanded = expandedTopFilter !== "";
    const numberOfSelectedFilters = Object.entries(selectedFilters ?? {})
      .map(([_, values]) => values)
      .flatMap((f) => f).length;

    const handleOnOutsideClick = () =>
      !isMobile && ((isStickyFilter && !filterSelected) || !isStickyFilterBar)
        ? closeDropdownFilter(dispatch)
        : null;

    const handleClickRemoveAllFilters = () => {
      const longTailUrl =
        hasValue(longTailFacets) && hasValue(longTailPattern)
          ? new UrlPath(new URL(basePath), [], longTailFacets, longTailPattern)
          : undefined;
      if (hasNoValue(longTailUrl)) {
        return onChangeFilter({ action: "removeAll" });
      }
      onChangeFilter({
        action: "removeAllWithLongtailUrl",
        longTailUrl: longTailUrl.getUpdatedLongTailPath(),
      });
    };

    const MobileFilterBarHeader = () => (
      <MobileFilterBarHeaderStyled>
        <span>{t("filters.popular.filters")}</span>
        <FilterAllFilters
          name={t("filters.and.sort")}
          className="all-filters-btn"
        />
      </MobileFilterBarHeaderStyled>
    );

    const useMemoizedFacets = ({
      isFilterBar,
      numberOfVisibleFilters,
    }: {
      isFilterBar: boolean;
      numberOfVisibleFilters?: number;
    }) =>
      useMemo(
        () =>
          isNotNull(facets)
            ? facets
                .slice(0, numberOfVisibleFilters)
                .map(({ label, id, type, ...facet }) =>
                  RANGE_FILTER_MAX in facet &&
                  hasValue(facet[RANGE_FILTER_MAX]) &&
                  RANGE_FILTER_MIN in facet &&
                  hasValue(facet[RANGE_FILTER_MIN]) &&
                  hasValue(onChangeRangeFilter) ? (
                    <RangeFilterAccordion
                      accordionProps={{
                        id,
                        label,
                      }}
                      attributeName={label}
                      category=""
                      isFilterBar={isFilterBar}
                      key={id}
                      onUpdate={onChangeRangeFilter(id)}
                      range={{
                        max: facet[RANGE_FILTER_MAX],
                        min: facet[RANGE_FILTER_MIN],
                      }}
                      selectedMax={facet.maxSelected}
                      selectedMin={facet.minSelected}
                      totalHits={totalHits}
                    />
                  ) : (
                    <DistinctFilterAccordion
                      attributeName={id}
                      category=""
                      filterItems={
                        "values" in facet
                          ? facet.values?.map(
                              ({
                                count,
                                id: item,
                                label,
                                selected,
                                ...rest
                              }) => ({
                                color: "color" in rest ? rest.color : undefined,
                                count,
                                item,
                                label,
                                selected,
                              })
                            )
                          : []
                      }
                      filterName={label}
                      isClearFiltersClicked={false} // TODO: XD-14109
                      isFilterBar={isFilterBar}
                      isSearchable={false} // TODO: XD-14109
                      key={id}
                      longTailFacets={longTailFacets}
                      longTailPattern={longTailPattern}
                      onChangeFilter={onChangeFilter}
                      selectedFilters={Object.entries(
                        selectedFilters ?? []
                      ).map(([key, selected]) => ({
                        attributeName: key,
                        type: "distinct" as const,
                        selected,
                      }))}
                      totalHits={totalHits}
                      shouldAutomaticallyScrollToFilterBar={
                        shouldAutomaticallyScrollToFilterBar
                      }
                    />
                  )
                )
            : null,
        [facets, isFilterBar, numberOfVisibleFilters, selectedFilters]
      );
    const memoizedFacetsForFilterBar = useMemoizedFacets({
      isFilterBar: true,
      numberOfVisibleFilters: MAX_NUMBER_OF_FILTERS,
    });
    const memoizedFacetsForNonFilterBar = useMemoizedFacets({
      isFilterBar: false,
    });

    return (
      <>
        <OutsideClickHandler
          disabled={!fiterDropdownExpanded}
          onOutsideClick={handleOnOutsideClick}
          useCapture={true}
          display="contents"
        >
          <FilterBarWrapperStyled
            id="filter-bar"
            ref={ref}
            isStickyFilter={isStickyFilter}
          >
            <FilterBarStyled
              isLoading={false}
              className={`filter-bar ${
                isLaptop && isStickyFilter ? "container" : ""
              }`}
            >
              {isMobileOrTablet ? <MobileFilterBarHeader /> : null}
              <FilterItemsStyled>
                {!isMobile ? (
                  <FilterArticleCount numberOfProducts={totalHits} />
                ) : null}
                {isNotNull(memoizedFacetsForFilterBar) ? (
                  memoizedFacetsForFilterBar
                ) : (
                  <LegacyFilters
                    isFilterBar={true}
                    numberOfVisibleFilters={MAX_NUMBER_OF_FILTERS}
                  />
                )}
                {isNotNull(sortOptions) ? (
                  <FilterSortItems
                    shouldAutomaticallyScrollToFilterBar={
                      shouldAutomaticallyScrollToFilterBar
                    }
                    id="sort-by"
                    onChangeSortOption={onChangeSortOption}
                    selectedSort={selectedSort}
                    sortOptions={sortOptions}
                    totalHits={totalHits}
                  />
                ) : (
                  <LegacyFilterSortItems id="sort-by" />
                )}
                {!isMobileOrTablet && (
                  <FilterAllFilters name={t("filters.all.filters")} />
                )}
                {isNotNull(selectedFilters) ? (
                  <RemoveAllFilters
                    numberOfSelectedFilters={numberOfSelectedFilters}
                    onclick={handleClickRemoveAllFilters}
                    variant={isMobileOrTablet ? "button" : "link"}
                  />
                ) : (
                  <LegacyRemoveAllFilters
                    variant={isMobileOrTablet ? "button" : "link"}
                  />
                )}
              </FilterItemsStyled>
              {!isMobileOrTablet && shouldShowFilterGridSwitch ? (
                <FilterGridSwitch
                  numberOfProducts={totalHits}
                  selectedColumnsNumber={selectedColumnsNumber}
                />
              ) : null}
            </FilterBarStyled>
          </FilterBarWrapperStyled>
        </OutsideClickHandler>
        {isNotNull(memoizedFacetsForNonFilterBar) ? (
          <FilterDrawer
            shouldAutomaticallyScrollToFilterBar={
              shouldAutomaticallyScrollToFilterBar
            }
            numberOfSelectedFilters={numberOfSelectedFilters}
            onChangeSortOption={onChangeSortOption}
            onRemoveAllFilters={handleClickRemoveAllFilters}
            selectedSort={selectedSort}
            sortOptions={sortOptions}
            totalHits={totalHits}
          >
            {memoizedFacetsForNonFilterBar}
          </FilterDrawer>
        ) : (
          <LegacyFilterDrawer
            onChangeSortOption={onChangeSortOption}
            selectedSort={selectedSort}
            sortOptions={sortOptions}
          />
        )}
      </>
    );
  }
);

FilterBar.displayName = "FilterBar";

export { FilterBar };
