import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { MQ, typographyToCss } from "../../../styles/helpers";
import spaces from "../../../styles/theme/spaces";
import { GRID_COLUMNS } from "../../../components/Filter/FilterBar/constants";
import typography from "./OneCardContentComponent.typography";

const bigHeadingHeight = "350px";
const smallHeadingHeight = "300px";

type OneCardContainerProps = {
  columnAmount?: number;
};

export const OneCardContainer = styled.li<OneCardContainerProps>(
  ({ columnAmount = GRID_COLUMNS.two }) => css`
    position: relative;
    display: flex;
    flex-direction: row;
    height: 180px;
    width: 100%;
    grid-column: 1 / span ${columnAmount};

    ${MQ("tablet")} {
      grid-column: auto;
      flex-direction: column;
      height: 100%;
    }
  `
);

type HeadingProps = {
  color: string;
  backgroundColor: string;
} & OneCardContainerProps;

export const Heading = styled.div<HeadingProps>(
  ({ columnAmount, color, backgroundColor }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    height: 100%;
    padding: ${spaces.smallRegular};
    box-sizing: border-box;
    background-color: ${backgroundColor};
    color: ${color};

    ${MQ("tablet")} {
      width: 100%;
      height: ${columnAmount === GRID_COLUMNS.four
        ? bigHeadingHeight
        : smallHeadingHeight};
      padding: ${columnAmount === GRID_COLUMNS.four
        ? `${spaces.large} 64px ${spaces.large} ${spaces.large}`
        : `${spaces.smallRegular} ${spaces.large} ${spaces.smallRegular} ${spaces.smallRegular}`};
    }
  `
);

export const TextWrapper = styled.div<OneCardContainerProps>(
  ({ columnAmount }) => css`
    display: flex;
    gap: ${spaces.mini};
    flex-direction: column;

    ${MQ("tablet")} {
      gap: ${columnAmount === GRID_COLUMNS.four
        ? spaces.smallRegular
        : spaces.mini};
    }
  `
);

export const Title = styled.h3<OneCardContainerProps>(
  ({ columnAmount }) => css`
    ${typographyToCss(typography.smallTitle)}
    text-align: left;
    margin: 0;
    height: 90px;
    display: block;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;

    ${MQ("tablet")} {
      ${typographyToCss(
        columnAmount === GRID_COLUMNS.four
          ? typography.bigTitle
          : typography.smallTitle
      )}
      height: ${columnAmount === GRID_COLUMNS.four ? "208px" : "180px"};
      -webkit-line-clamp: ${columnAmount === GRID_COLUMNS.four ? "6" : "8"};
    }
  `
);

export const Label = styled.div<HeadingProps>(
  ({ backgroundColor, color, columnAmount }) => css`
    width: fit-content;
    padding: ${spaces.micro} 5px;
    font-size: ${spaces.smallRegular};
    color: ${color};
    background-color: ${backgroundColor};

    ${MQ("laptop")} {
      padding: ${columnAmount === GRID_COLUMNS.four
        ? `${spaces.miniMicro} 5px`
        : `${spaces.micro} ${spaces.mini}`};
    }
  `
);

export const ImageWrapper = styled.figure<OneCardContainerProps>(
  ({ columnAmount }) => css`
    position: absolute;
    height: 100%;
    width: 50%;
    left: 50%;

    ${MQ("tablet")} {
      left: 0;
      width: 100%;
      top: ${columnAmount === GRID_COLUMNS.four
        ? bigHeadingHeight
        : smallHeadingHeight};
      height: ${columnAmount === GRID_COLUMNS.four
        ? `calc(100% - ${bigHeadingHeight})`
        : `calc(100% - ${smallHeadingHeight})`};
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `
);

export const LinkText = styled.span`
  ${typographyToCss(typography.link)};
  display: flex;
  align-items: center;
  gap: ${spaces.miniMicro};
  color: inherit;
  margin-top: ${spaces.smallRegular};
`;

export const Link = styled.a`
  display: flex;
  height: 100%;
  width: 100%;
`;
