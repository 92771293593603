import styled from "@emotion/styled/macro";
import { typographyToCss } from "../../styles/helpers";
import { xxlTheme } from "../../styles/xxl-theme";

const { spaces } = xxlTheme;

const LinkTypography = {
  fontFamily: "var(--font-family-regular)",
  fontSize: 15,
  lineHeight: 1.2,
  letterSpacing: -0.15,
  textDecoration: "underline",
};

const SuggestionsTypography = {
  fontFamily: "var(--font-family-regular)",
  fontSize: 15,
  lineHeight: 1.2,
  letterSpacing: -0.15,
};

export const Link = styled.a`
  ${typographyToCss(LinkTypography)};
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: 0;
  cursor: pointer;
`;

export const SearchSuggestionsWrapper = styled.p`
  ${typographyToCss(SuggestionsTypography)};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${spaces.mini};
  margin: 0 0 ${spaces.large};
`;
