import styled from "@emotion/styled/macro";
import { Button } from "../../../styled";
import {
  interactionFeedback,
  MQ,
  xxlScrollbars,
} from "../../../styles/helpers";
import { FadingLoader } from "../../Search/SearchContent.styled";
import isPropValid from "@emotion/is-prop-valid";
import { xxlTheme, containerMaxWidth } from "../../../styles/xxl-theme";
const { colors, iconSize, spaces, typography, zIndex } = xxlTheme;

import { css } from "@emotion/react";
import {
  FILTER_DROPDOWN_HEIGHT,
  MOBILE_FILTER_CONTAINER_MIN_HEIGHT,
  MOBILE_FILTER_DROPDOWN_OFFSET,
  FILTER_DROPDOWN_WIDTH,
  FILTER_SLIDER_DROPDOWN_WIDTH,
  STICKY_FILTER_NEGATIVE_MARGIN_MOBILE,
} from "../FilterBar/constants";

import { HEADER_SHADOW } from "../FilterDrawer/constants";

type ExpandableFilterProps = {
  isExpanded?: boolean;
};

const ArticlesCountStyled = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: ${spaces.large};
  ${typography.mediumRegular};
  letter-spacing: -0.15px;
  gap: ${spaces.micro};
`;

const ExpandIconStyled = styled.span<ExpandableFilterProps>(
  ({ isExpanded = false }) => css`
    display: none;
    justify-content: center;
    align-items: center;
    width: ${iconSize.small};
    height: ${iconSize.small};
    color: ${colors.xxlDarkGrey};

    svg {
      transition: all 200ms;
      transform: ${isExpanded ? "rotate(180deg)" : "none"};
    }

    ${MQ("tabletHorizontal")} {
      display: flex;
    }
  `
);

const AllFiltersStyledBtn = css`
  ${typography.mediumRegular};
  background-color: ${colors.xxlWhite};
  padding: 0;
  color: ${colors.xxlBlack};

  ${MQ("tabletHorizontal")} {
    padding: ${spaces.miniRegular} ${spaces.regular};
  }
`;

const AllFiltersStyled = styled(Button)<ExpandableFilterProps>(
  ({ isExpanded = false }) => css`
    background-color: ${colors.xxlLightGreen};
    border: 0;
    display: inline-flex;
    line-height: 18px;
    padding: ${spaces.miniRegular} ${spaces.regular};
    align-items: center;
    gap: ${spaces.mini};
    color: ${colors.xxlBlack};
    ${interactionFeedback(`background-color: ${colors.xxlGreen};`)};
    ${typography.mediumRegular};
    cursor: pointer;

    svg {
      width: ${spaces.smallRegular};
      height: ${spaces.smallRegular};
    }

    ${MQ("tabletHorizontal")} {
      margin-right: ${spaces.mini};
      border: 1px solid ${colors.xxlMediumGrey};
      ${typography.mediumBold};
      background-color: ${isExpanded ? colors.xxlMediumGrey : colors.xxlWhite};
      ${interactionFeedback(`background-color: ${colors.xxlLightGrey};`)};
    }

    &.all-filters-btn {
      ${AllFiltersStyledBtn}
    }
  `
);

type FilterBarWrapperStyledProps = {
  isStickyFilter?: boolean;
};

const FilterBarWrapperStyled = styled.div<FilterBarWrapperStyledProps>(
  ({ isStickyFilter = false }) => css`
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;

    ${isStickyFilter
      ? `
      width: 100%;
      min-height: auto;
      justify-content: center;
      position: sticky;
      inset: 0 0 auto 0;
      z-index: ${zIndex.filterBar};
      background: ${colors.xxlWhite};
      padding-top: ${spaces.regular};
      box-shadow: ${HEADER_SHADOW};
      margin: 0 -${STICKY_FILTER_NEGATIVE_MARGIN_MOBILE}px;
      padding: ${spaces.smallRegular} ${spaces.smallRegular} 0;

    .filter-bar {
      padding-top: 0;
      border: 0;
    }

    ${MQ("tabletHorizontal")} {
      padding-top: ${spaces.smallRegular};
      .filter-bar {
        margin-bottom: 0;
      }
    }

    ${MQ("laptop")} {
      padding: ${spaces.smallRegular} 0 0 0;
      width: calc(100% + ${STICKY_FILTER_NEGATIVE_MARGIN_MOBILE}px);
    }

    ${MQ("bigDesktop")} {      
      margin: 0 calc(-1 * (100vw - ${containerMaxWidth}px) /2);
      width: 100vw;
    }
    `
      : ``}
  `
);

const FilterBarStyled = styled(FadingLoader, {
  shouldForwardProp: isPropValid,
})`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: ${spaces.smallLarge};
  padding-top: ${spaces.large};
  border-top: 1px solid ${colors.xxlLightGrey};

  @supports (-webkit-overflow-scrolling: touch) {
    margin-bottom: ${spaces.micro};
  }

  ${MQ("tablet")} {
    padding-top: 0;
    border: 0;
  }

  ${MQ("tabletHorizontal")} {
    flex-direction: row;
    align-items: center;
    overflow: visible;
    flex-wrap: wrap;
    gap: ${spaces.regular};
    margin-bottom: ${spaces.smallRegular};
  }
`;

const FilterItemStyled = styled.div<ExpandableFilterProps>(
  ({ isExpanded = false }) => css`
    display: flex;
    border: 0;
    padding: 0;
    margin-right: ${spaces.mini};
    position: relative;
    background-color: ${colors.xxlLightGreen};
    ${interactionFeedback(`background-color: ${colors.xxlGreen};`)};

    ${MQ("tabletHorizontal")} {
      border: 1px solid ${colors.xxlMediumGrey};
      background-color: ${isExpanded ? colors.xxlMediumGrey : colors.xxlWhite};
      ${interactionFeedback(`background-color: ${colors.xxlLightGrey};`)};
    }
  `
);

const FilterItemHeaderStyled = styled(Button)`
  display: flex;
  line-height: 18px;
  padding: ${spaces.miniRegular} ${spaces.smallRegular};
  align-items: flex-start;
  gap: ${spaces.mini};
  ${typography.mediumRegular};
  flex-wrap: nowrap;
  background: transparent;
  border: 0;
  color: ${colors.xxlBlack};

  ${MQ("tabletHorizontal")} {
    ${typography.mediumBold};
  }
`;

type FilterItemContentStyledProps = {
  isExpanded?: boolean;
  isColorFilter?: boolean;
  isRangeFilter?: boolean;
};

const FilterItemContentStyled = styled.div<FilterItemContentStyledProps>(
  ({ isExpanded = false, isRangeFilter = false, isColorFilter = false }) => css`
    display: ${isExpanded ? "block" : "none"};
    position: absolute;
    top: 45px;
    left: 0;
    bottom: auto;
    background-color: ${colors.xxlWhite};
    border: 1px solid ${colors.xxlMediumGrey};
    padding: ${spaces.smallRegular};
    z-index: ${zIndex.navigation};
    min-width: ${FILTER_DROPDOWN_WIDTH}px;
    overflow-x: auto;

    ul li:first-of-type a,
    div:first-of-type,
    div label:first-of-type {
      padding-top: 0;
      margin-top: 0;
    }

    div:first-of-type {
      padding-top: ${isColorFilter ? spaces.hair : 0};
    }

    & div.search-list__no-result-wrapper {
      margin: ${spaces.regular} 0 0 0;

      .search-list__no-result-headline {
        display: none;
      }
      .search-list__no-result-message {
        padding: 0;
      }
    }

    ${MQ("tabletHorizontal")} {
      min-width: ${isRangeFilter
        ? FILTER_SLIDER_DROPDOWN_WIDTH
        : FILTER_DROPDOWN_WIDTH}px;
    }
  `
);

const FilterItemContentScrollStyled = styled.div<FilterItemContentStyledProps>`
  overflow-y: auto;
  max-height: ${FILTER_DROPDOWN_HEIGHT}px;
  padding: ${spaces.micro} ${spaces.smallRegular} ${spaces.micro} 0;
  ${xxlScrollbars(spaces.micro)};
`;

const FilterItemsStyled = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  ${xxlScrollbars(spaces.micro)};
  ${typography.mediumRegular};
  width: 100%;
  padding-bottom: ${spaces.smallRegular};

  @supports (-webkit-overflow-scrolling: touch) {
    padding-bottom: ${spaces.smallRegular};
  }

  ${MQ("tabletHorizontal")} {
    overflow: visible;
    width: auto;
  }
`;

const FilterSortListStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spaces.smallLarge};
  padding-bottom: ${spaces.line};
`;

const FilterSortLinkStyled = styled("a")`
  padding: 0;
`;

const FilterSortLabelStyled = styled("label")`
  && {
    margin: 0;
    ${typography.mediumRegular};
  }
`;

const GridSwitchStyled = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  ${typography.mediumBold};
  gap: ${spaces.mini};
  margin-left: auto;
  width: 100%;

  ${MQ("tabletHorizontal")} {
    margin-top: -${spaces.smallRegular};
    width: auto;
  }
`;

type GridSwitchItemProps = {
  isActive?: boolean;
};

const GridSwitchItemStyled = styled.a<GridSwitchItemProps>(
  ({ isActive = false }) => css`
    ${typography.mediumBold};
    color: ${isActive ? colors.xxlGreen : colors.xxlDarkGrey};
    cursor: pointer;

    ${interactionFeedback(`
      color:${isActive ? colors.xxlDarkGrey : colors.xxlBlack};
      cursor: ${isActive ? "text" : "pointer"};
      text-decoration: none;
      `)};

    ${MQ("tabletHorizontal")} {
      color: ${isActive ? colors.xxlDarkGrey : colors.xxlGrey};
    }
  `
);

const MobileFilterBarHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${spaces.miniRegular};
  ${typography.mediumRegular};
`;

const MobileFilterDrawerHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${spaces.large} ${spaces.smallRegular} ${spaces.smallRegular};
`;

const MobileFilterDrawerContentStyled = styled.div`
  display: flex;
  align-items: flex-start;
  padding: ${spaces.miniRegular};
  margin-right: ${spaces.smallRegular};
  min-height: ${MOBILE_FILTER_CONTAINER_MIN_HEIGHT}px;
  max-height: calc(100dvh - ${MOBILE_FILTER_DROPDOWN_OFFSET}px);
  overflow-y: auto;
  ${xxlScrollbars(spaces.micro)};

  div.MuiPaper-root {
    width: 100%;
    padding-top: 0;
    gap: ${spaces.miniRegular};

    label:first-of-type {
      margin-top: 0;
    }
  }

  ul {
    padding-bottom: ${spaces.smallLarge};

    li:first-of-type a {
      padding-top: 0;
    }
  }
`;

const MobileFilterDraweFooterStyled = styled.div`
  display: flex;
  gap: ${spaces.mini};
  box-sizing: border-box;
  width: 100%;
  padding: ${spaces.smallRegular};
  background-color: ${colors.xxlWhite};
`;

const RemoveAllFiltersStyled = styled.div`
  padding: ${spaces.miniRegular} ${spaces.smallRegular};
  border: 1px solid ${colors.xxlMediumGrey};
  background-color: ${colors.xxlLightGrey};
  ${interactionFeedback(`background-color: ${colors.xxlMediumGrey};`)};

  button {
    color: ${colors.xxlBlack};
  }
`;

export {
  ArticlesCountStyled,
  ExpandIconStyled,
  AllFiltersStyled,
  FilterBarStyled,
  FilterBarWrapperStyled,
  FilterItemStyled,
  FilterItemHeaderStyled,
  FilterItemContentStyled,
  FilterItemContentScrollStyled,
  FilterItemsStyled,
  FilterSortLabelStyled,
  FilterSortLinkStyled,
  FilterSortListStyled,
  GridSwitchItemStyled,
  GridSwitchStyled,
  MobileFilterBarHeaderStyled,
  MobileFilterDraweFooterStyled,
  MobileFilterDrawerHeaderStyled,
  MobileFilterDrawerContentStyled,
  RemoveAllFiltersStyled,
};
