import { styled } from "@mui/material/styles";
import { MQ } from "../../../styles/helpers";
import { xxlTheme } from "../../../styles/xxl-theme";
const { spaces } = xxlTheme;

export const AvailabilityBarStyled = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;

  ${MQ("tablet")} {
    flex-direction: row;
    margin-bottom: ${spaces.large};
  }

  ${MQ("tabletHorizontal")} {
    width: auto;
  }
`;

export const AvailabilityBarPlaceholder = styled("div")`
  min-height: ${spaces.smallLarge};
  display: block;
  content: "";
`;
