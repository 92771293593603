import styled from "@emotion/styled/macro";
import { typographyToCss, MQ } from "../../styles/helpers";
import { xxlTheme } from "../../styles/xxl-theme";

const { spaces } = xxlTheme;

const HeadingTypography = {
  fontFamily: "var(--font-family-bold)",
  fontSize: 20,
  lineHeight: 1.2,
  letterSpacing: -0.15,

  laptop: {
    fontSize: 34,
  },
};

type FadingLoaderProps = {
  isLoading: boolean;
};

export const FadingLoader = styled.div`
  transition: opacity 300ms;
  opacity: ${({ isLoading }: FadingLoaderProps): number =>
    isLoading ? 0.3 : 1};
  pointer-events: ${({ isLoading }: FadingLoaderProps): string =>
    isLoading ? "none" : "auto"};
  width: 100%;
`;

export const StyledHeading = styled.h2`
  ${typographyToCss(HeadingTypography)};
  margin: ${spaces.large} 0 ${spaces.smallRegular};

  ${MQ("laptop")} {
    margin: ${spaces.big} 0 ${spaces.smallLarge};
  }
`;
