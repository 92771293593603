import type { FunctionComponent, PropsWithChildren } from "react";
import React from "react";
import styled from "@emotion/styled/macro";

const FocusableLinkStyled = styled.a`
  &:focus {
    outline: 1px dotted #212121;
    outline: 5px auto -webkit-focus-ring-color;
  }
  & {
    padding: 8px 0;
  }
`;

type FocusableLinkProps = {
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLAnchorElement>) => void;
  tabIndex?: number;
};

export const FocusableLink: FunctionComponent<
  PropsWithChildren<FocusableLinkProps>
> = ({
  children,
  tabIndex = 0, // Default to always tabbable
  ...props
}) => {
  const role = "link";
  return (
    <FocusableLinkStyled {...{ role, tabIndex, ...props }}>
      {children}
    </FocusableLinkStyled>
  );
};
