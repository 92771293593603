import React from "react";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import type { TranslationKey } from "../../translations";
import { SearchSuggestion } from "./SearchSuggestion";
import { SearchSuggestionsWrapper } from "./SearchSuggestions.styled";

export type SearchSuggestionsType = string[];
export type SearchSuggestionsProps = {
  makeSense: boolean;
  searchSuggestions: SearchSuggestionsType;
  useSearchV2?: boolean;
};

const MAX_NUMBER_OF_SUGGESTIONS = 5;

export const SearchSuggestions: React.FunctionComponent<
  SearchSuggestionsProps
> = ({ makeSense, searchSuggestions, useSearchV2 = false }) => {
  const { t } = useTranslations();
  return (
    <SearchSuggestionsWrapper>
      {t(
        makeSense
          ? ("search.page.related.searches" as TranslationKey)
          : ("search.page.did.you.mean" as TranslationKey)
      )}
      {searchSuggestions.slice(0, MAX_NUMBER_OF_SUGGESTIONS).map((item) => (
        <SearchSuggestion
          key={item}
          suggestion={item}
          useSearchV2={useSearchV2}
        />
      ))}
    </SearchSuggestionsWrapper>
  );
};
