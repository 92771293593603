import type { Store } from "@xxl/content-api";
import { color } from "@xxl/theme";
import React, { useEffect, useState, type PropsWithChildren } from "react";
import { XxlStack } from "../../../../components/Common/XxlStack";
import { useTranslations } from "../../../../contexts/Translations/TranslationsContext";
import { useXxlMediaQuery } from "../../../../hooks/useXxlMediaQuery";
import spaces from "../../../../styles/theme/spaces";
import { DialogBox } from "../../../DialogBox";
import { StoreStockSelectedDetailsSection } from "../../../StoreStock";
import { XXLLoader } from "../../../XXLLoader";
import type { viewStates } from "../MultipleAvailabilitySelector";
import { computedRegionName } from "../MultipleAvailabilitySelector.helper";
import { CheckboxAllStores } from "./CheckboxAllStores";
import { DialogFooter } from "./Footer";
import { type StoreWithId, type TRegionalStore } from "./helpers";
import { StoreFieldList } from "./StoreFiledList";
import {
  DetailsWrapper,
  ListContainer,
  StoreInput,
  StoreSelectionWrapper,
  StoresWrapper,
} from "./styles";

type Props = PropsWithChildren<{
  filterKey: string;
  isLoading: boolean;
  isOpen: boolean;
  onClose: (isUpdated: boolean) => void;
  onFilterKeyChange: (value: string) => void;
  onStoreSelect: (ids: string[]) => void;
  selectedRegion?: TRegionalStore;
  selectedStoreIds: string[];
  storeListItems: StoreWithId[];
  storeRegions: TRegionalStore[];
  stores: StoreWithId[];
  viewState: viewStates;
}>;

export const DialogStoreSelect = ({
  isOpen,
  onClose,
  onStoreSelect,
  isLoading,
  stores,
  selectedStoreIds,
  storeListItems,
  storeRegions,
  filterKey,
  onFilterKeyChange,
  viewState,
}: Props) => {
  const { t } = useTranslations();
  const isMobile = useXxlMediaQuery("MobileMediaQuery");
  const hasStores = stores.length > 0;
  const [isUpdated, setIsUpdated] = useState(() => false);

  useEffect(() => {
    setIsUpdated(viewState === "REGION"); //for REGION we select all items for region by default, so it's initially "updated"
  }, [viewState]);
  const matchesFilterInput = (store: Store) => {
    if (filterKey.length === 0) {
      return true;
    }
    return store.name
      ?.toLocaleLowerCase()
      .includes(filterKey.toLocaleLowerCase());
  };

  const getRegionNameFromId = (id: string) =>
    computedRegionName(stores.find((s) => s.id === id)?.name ?? "");

  const onHandleChange = (
    checked: boolean,
    storeId: string,
    storeIds: string[]
  ) => {
    setIsUpdated(true);
    if (checked) {
      const idSet = new Set([...selectedStoreIds, ...storeIds, storeId]);
      const checkedRegionName = getRegionNameFromId(storeId);
      const region = storeRegions.find((s) => s.name === checkedRegionName);
      const isAllRegionIdsSelected = region?.regionIds.every((item) =>
        idSet.has(item)
      );
      const checkedRegionIds =
        storeRegions.find((s) => s.name === checkedRegionName)?.id ?? "";
      if (isAllRegionIdsSelected === true) {
        idSet.add(checkedRegionIds);
      }
      onStoreSelect([...idSet]);
      return;
    }
    const idSet = new Set(selectedStoreIds);
    for (const id of [...storeIds, storeId]) {
      idSet.delete(id);
    }

    const unCheckedRegionName = getRegionNameFromId(storeId);
    const region = storeRegions.find((s) => s.name === unCheckedRegionName);
    const isAllRegionIdsSelected = region?.regionIds.every((item) =>
      idSet.has(item)
    );
    const uncheckedRegionIds =
      storeRegions.find((s) => s.name === unCheckedRegionName)?.id ?? "";
    if (isAllRegionIdsSelected !== true) {
      idSet.delete(uncheckedRegionIds);
    }
    onStoreSelect([...idSet]);
    return;
  };

  const handleClose = () => {
    onClose(isUpdated);
    setIsUpdated(false);
  };
  const handleCancelClick = () => {
    onClose(false);
    setIsUpdated(false);
  };

  const computedStores = storeListItems
    .filter(matchesFilterInput)
    .sort((a, b) => a.name.localeCompare(b.name));

  const isAllStoresChecked = selectedStoreIds.length === stores.length;
  const shouldShowLoadingState = !hasStores && isLoading;

  return (
    <DialogBox
      handleDialogBoxClosing={handleCancelClick}
      hasPadding={true}
      isDialogBoxOpen={isOpen}
      dialogBoxHeadline={t("storeselect.multiple.header")}
      dialogBoxSize={"md"}
      contentStyle={{ overflow: "scroll" }}
      isFullHeight={true}
    >
      <StoreSelectionWrapper>
        <StoreInput
          data-testid="store-filter-input"
          onChange={(e) => onFilterKeyChange(e.currentTarget.value)}
          value={filterKey}
          disabled={!hasStores}
          placeholder={t("storeselect.search.placeholder")}
        />
        <StoresWrapper>
          {!shouldShowLoadingState ? (
            <ListContainer>
              <CheckboxAllStores
                computedStores={computedStores}
                onStoreSelect={() => {
                  setIsUpdated(true);
                  onStoreSelect(
                    isAllStoresChecked ? [] : stores.map((store) => store.id)
                  );
                }}
                isChecked={isAllStoresChecked}
              />
              <XxlStack my={"12px"}>
                <XxlStack
                  borderBottom={`${spaces.hair} solid ${color.mediumGray.hex}`}
                />
              </XxlStack>
              <StoreFieldList
                filteredStores={computedStores}
                stores={stores}
                isMobile={isMobile}
                selectedIds={selectedStoreIds}
                handleChange={onHandleChange}
              />
            </ListContainer>
          ) : (
            <XXLLoader />
          )}
        </StoresWrapper>
        {!isMobile && selectedStoreIds.length > 0 && (
          <DetailsWrapper>
            {stores
              .filter((store) => selectedStoreIds.includes(store.id))
              .map((store) => (
                <StoreStockSelectedDetailsSection
                  key={store.id}
                  store={store}
                  isMobile={false}
                />
              ))}
          </DetailsWrapper>
        )}

        <DialogFooter
          isMobile={isMobile}
          handleCancelClick={handleCancelClick}
          handleClose={handleClose}
        />
      </StoreSelectionWrapper>
    </DialogBox>
  );
};
