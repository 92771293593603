import type { FunctionComponent, PropsWithChildren } from "react";
import React from "react";
import {
  Accordion as StyledAccordion,
  AccordionDetails,
  AccordionHeading,
  AccordionSummary,
  ExpandIconStyled,
} from "./Accordion.styled";
import type { TransitionProps } from "@mui/material/transitions/transition";

export type AccordionProps = {
  expanded?: boolean;
  id: string;
  label: string;
  transitionProps?: TransitionProps;
  className?: string;
  onChange?: (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
};

export const Accordion: FunctionComponent<
  PropsWithChildren<AccordionProps>
> = ({
  children,
  className,
  expanded = false,
  label,
  id,
  onChange,
  transitionProps,
}) => {
  return (
    <StyledAccordion
      className={className}
      data-testid={`filter-${id.toLowerCase()}`}
      onChange={onChange}
      TransitionProps={{
        ...transitionProps,
        unmountOnExit: true,
      }}
      expanded={expanded}
    >
      <AccordionSummary
        aria-controls={`${id}-content`}
        id={`${id}-header`}
        expandIcon={<ExpandIconStyled />}
      >
        <AccordionHeading className={expanded ? "active" : ""}>
          {label}
        </AccordionHeading>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </StyledAccordion>
  );
};
