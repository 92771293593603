/* TODO: Remove in XD-13966 */
import React from "react";
import { REMOVE_ALL_FILTERS } from "../../Search/Constants";
import { useSearchContext } from "../../Search/SearchState";
import { getSelectedFiltersCount } from "../../Filter/FilterHelper";
import type { SortOrderData } from "@xxl/product-search-api";
import { FilterDrawer } from "./FilterDrawer";
import { LegacyFilters } from "../FilterMenu/Filters";

export const LegacyFilterDrawer = ({
  onChangeSortOption,
  selectedSort,
  sortOptions,
}: {
  onChangeSortOption?: (value: string) => void;
  selectedSort: SortOrderData["selected"] | null;
  sortOptions: SortOrderData["options"] | null;
}) => {
  const { state, dispatch } = useSearchContext();
  const { searchData, selectedFilters } = state;
  const onRemoveAllFilters = () => dispatch({ type: REMOVE_ALL_FILTERS });

  return (
    <FilterDrawer
      numberOfSelectedFilters={getSelectedFiltersCount(selectedFilters)}
      onChangeSortOption={onChangeSortOption}
      onRemoveAllFilters={onRemoveAllFilters}
      selectedSort={selectedSort}
      sortOptions={sortOptions}
      totalHits={searchData?.results?.count ?? 0}
    >
      <LegacyFilters isFilterBar={false} numberOfVisibleFilters={null} />
    </FilterDrawer>
  );
};
