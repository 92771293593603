export default {
  title: {
    fontSize: 20,
    lineHeight: 1.1,
    tablet: {
      fontSize: 24,
      lineHeight: 1.16,
    },
  },
  link: {
    fontSize: 12,
    lineHight: 1.5,
    fontFamily: "var(--font-family-medium)",
    tablet: {
      fontSize: 15,
      lineHight: 1.25,
    },
  },
};
