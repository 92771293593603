/* TODO: Remove in XD-13966 */
import * as React from "react";
import { useSearchContext } from "../Search/SearchState";
import { SortItem } from "./SortItem";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import type {
  EntitySortingParameter,
  EntitySortingParameterCustomNameEnum,
} from "@xxl/search-api";
import { useTracking } from "../../contexts/Tracking";

export const LegacySortItems = ({ idPrefix }: { idPrefix: string }) => {
  const {
    dispatch,
    state: { categoryInfo, selectedSort, sorts, query },
  } = useSearchContext();
  const { t } = useTranslations();
  const trackers = useTracking();
  const [selected = {}] = selectedSort;

  const handleClickSort =
    ({
      asEntitySortingParameter,
      sortType,
    }: {
      asEntitySortingParameter: () => EntitySortingParameter;
      sortType: EntitySortingParameterCustomNameEnum;
    }) =>
    () => {
      dispatch({ type: "CHANGE_SORT", payload: asEntitySortingParameter() });
      trackers.sendSortChangeEvent({
        category: categoryInfo?.category?.name,
        searchQuery: query,
        value: sortType,
      });
      window.history.replaceState(
        window.history.state,
        "",
        window.location.pathname + "?sort=" + sortType
      );
    };

  return (
    <>
      {sorts.map(({ asEntitySortingParameter, equals, name, sortType }) => (
        <div key={sortType}>
          <SortItem
            name={t(name)}
            onClick={handleClickSort({ asEntitySortingParameter, sortType })}
            isSelected={equals(selected)}
            value={sortType}
            idPrefix={idPrefix}
          />
        </div>
      ))}
    </>
  );
};
