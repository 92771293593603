import styled from "@emotion/styled/macro";
import { xxlTheme } from "../../../../../../styles/xxl-theme";

const { colors } = xxlTheme;

export const CountNumber = styled.span`
  position: absolute;
  right: 0;
  color: ${colors.xxlDarkGrey};
`;

export const EmptySearchResult = styled.div`
  font-size: 0.9rem;
  font-family: var(--font-family-regular);
  line-height: 32px;
  &::before {
    content: "${({ text }: { text: string }): string => text}";
  }
`;
