import type { FunctionComponent } from "react";
import type { AccordionProps } from "./Accordion";
import { AccordionSimple } from "./AccordionSimple";
import { List } from "./AccordionList.styled";

type AccordionListProps = AccordionProps & {
  isFetchingNewSearchResult: boolean;
  listItems: React.ReactElement[];
  totalHits: number;
};
export const AccordionListSimple: FunctionComponent<AccordionListProps> = ({
  id,
  label,
  className,
  isFetchingNewSearchResult,
  listItems,
  totalHits,
}) => {
  return (
    <AccordionSimple
      className={className}
      id={id}
      isFetchingNewSearchResult={isFetchingNewSearchResult}
      label={label}
      totalHits={totalHits}
    >
      <List>
        {listItems.map((item: React.ReactElement) => (
          <li key={item.key}>{item}</li>
        ))}
      </List>
    </AccordionSimple>
  );
};
