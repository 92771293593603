import React from "react";
import type { PropsWithChildren } from "react";
import { EditButton, LineDivider } from "./AvailabilitySelector.styled";
import { useTranslations } from "../../../../src/contexts/Translations/TranslationsContext";

export const EditSelectedRegionButton = ({
  onClick,
  isMobile,
  regionName = "",
}: PropsWithChildren<{
  onClick: () => void;
  isMobile: boolean;
  regionName?: string;
}>) => {
  const { t } = useTranslations();
  if (isMobile) return null;
  if (regionName.length === 0) return null;
  const storeRegionEditText = `${regionName}, ${t(
    "storefinder.warehouses.all"
  )}`;
  return (
    <>
      <LineDivider />
      <EditButton onClick={onClick}>{storeRegionEditText}</EditButton>
    </>
  );
};
