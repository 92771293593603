import type {
  GridCampaignCarousel,
  OneCardContentComponent as OneCardContentComponentData,
} from "@xxl/content-api";
import type { RecommendationsParametersStrategyEnum } from "@xxl/recommendations-api";
import type { ReactElement } from "react";
import React from "react";
import type { PageType } from "../../constants";
import type { PriceWithLabelsProps } from "../Product/PriceWithLabels/PriceWithLabels";
import type { CombinedProductData } from "../Product/product-helper";
import { CampaignCarousel } from "./CampaignCarousel";
import { OneCardContentComponent } from "./OneCardComponent/OneCardContentComponent";
import { ProductsRecommendationsCarousel } from "./ProductsRecommendationsCarousel";

type ProductsRecommendationsCarouselData = {
  carouselType: RecommendationsParametersStrategyEnum;
  contentIndexRef: React.MutableRefObject<number>;
  currentPage: number;
  currentProductIndex: number;
  isLoading: boolean;
  isMobile: boolean;
  nrOfOneCardContentComponents: number;
  nrOfProductsInRow: number;
  numberOfCampaignCarousels: number;
  numberOfSmallBanners: number;
  pageType: PageType;
  PriceComponents: ReactElement<PriceWithLabelsProps>[];
  productsPerCarousel: number;
  productsPerPage: number;
  recommendedProducts: CombinedProductData[];
  selectedColumnsNumber: number;
  slidersPerView: number;
  title?: string;
};

type CampaignCarouselData = {
  carouselData: GridCampaignCarousel;
  contentIndexRef: React.MutableRefObject<number>;
  currentProductIndex: number;
  isMobile: boolean;
  nrOfOneCardContentComponents: number;
  nrOfProductsInRow: number;
  numberOfRecommendationsCarousels: number;
  numberOfSmallBanners: number;
  pageType: PageType;
  PriceComponents: ReactElement<PriceWithLabelsProps>[];
  products: CombinedProductData[];
  selectedColumnsNumber: number;
  slidesPerView: number;
  campaignCarouselProductsData?: GridCampaignCarousel;
  positionForCampaignCarousel?: number;
};

const calculateRecommendationsCarouselPosition = (
  nrOfProductsInRow: number,
  page: number,
  productsPerPage: number,
  isMobile: boolean
): number => {
  // 1.5 used because we always want to have this carousel in the second row on the right side
  const position =
    (isMobile ? nrOfProductsInRow : nrOfProductsInRow * 1.5) +
    page * productsPerPage;
  return position - 1;
};

export const getProductsRecommendationsCarousel = ({
  carouselType,
  contentIndexRef,
  currentPage,
  currentProductIndex,
  isLoading,
  isMobile,
  nrOfOneCardContentComponents,
  nrOfProductsInRow,
  numberOfCampaignCarousels,
  numberOfSmallBanners,
  pageType,
  PriceComponents,
  productsPerCarousel,
  productsPerPage,
  recommendedProducts,
  selectedColumnsNumber,
  slidersPerView,
  title,
}: ProductsRecommendationsCarouselData): JSX.Element | null => {
  const page =
    contentIndexRef.current < currentPage
      ? contentIndexRef.current
      : currentPage;
  const carouselIndex = calculateRecommendationsCarouselPosition(
    nrOfProductsInRow,
    page,
    productsPerPage,
    isMobile
  );
  const numberOfAlreadyRenderedRecommendations = contentIndexRef.current;
  const spaceTakenByAlreadyRenderedRecommendationCarousels = isMobile
    ? 0
    : numberOfAlreadyRenderedRecommendations * slidersPerView;
  const spaceTakenByCampaignCarousel = isMobile
    ? 0
    : slidersPerView * numberOfCampaignCarousels;
  const numberOfSmallBannersNotCoveringTheWholeRow =
    numberOfSmallBanners % nrOfProductsInRow;
  const numberOfOneCardContentComponentsNotCoveringTheWholeRow =
    nrOfOneCardContentComponents % nrOfProductsInRow;

  if (
    carouselIndex -
      spaceTakenByAlreadyRenderedRecommendationCarousels -
      spaceTakenByCampaignCarousel -
      numberOfSmallBannersNotCoveringTheWholeRow -
      numberOfOneCardContentComponentsNotCoveringTheWholeRow ===
    currentProductIndex
  ) {
    const firstProductIndex = page * productsPerCarousel;
    const lastWantedProductIndex = (page + 1) * productsPerCarousel;
    const lastProductIndex =
      lastWantedProductIndex > recommendedProducts.length - 1
        ? recommendedProducts.length
        : lastWantedProductIndex;
    const hideCarousel = contentIndexRef.current === currentPage && isLoading;
    const productsForCarousel = recommendedProducts.slice(
      firstProductIndex,
      lastProductIndex
    );
    const enoughProductsAvailable =
      productsForCarousel.length >= slidersPerView;

    if (enoughProductsAvailable) {
      contentIndexRef.current += 1;
    }

    return !hideCarousel && enoughProductsAvailable ? (
      <ProductsRecommendationsCarousel
        carouselType={carouselType}
        currentProductIndex={currentProductIndex}
        isMobile={isMobile}
        nrOfProductsInRow={nrOfProductsInRow}
        pageType={pageType}
        PriceComponents={PriceComponents}
        products={productsForCarousel}
        selectedColumnsNumber={selectedColumnsNumber}
        slidersPerView={slidersPerView}
        title={title}
      />
    ) : null;
  }

  return null;
};

export const getCampaignCarousel = ({
  campaignCarouselProductsData,
  carouselData,
  contentIndexRef,
  currentProductIndex,
  isMobile,
  nrOfOneCardContentComponents,
  nrOfProductsInRow,
  numberOfRecommendationsCarousels,
  numberOfSmallBanners,
  pageType,
  positionForCampaignCarousel,
  PriceComponents,
  products,
  selectedColumnsNumber,
  slidesPerView,
}: CampaignCarouselData): JSX.Element | null => {
  const spaceTakenByRecommendationCarousels = isMobile
    ? 0
    : slidesPerView * numberOfRecommendationsCarousels;
  const numberOfSmallBannersNotCoveringTheWholeRow =
    numberOfSmallBanners % nrOfProductsInRow;
  const numberOfOneCardContentComponentsNotCoveringTheWholeRow =
    nrOfOneCardContentComponents % nrOfProductsInRow;
  const spaceTakenByGridExtras =
    spaceTakenByRecommendationCarousels +
    numberOfSmallBannersNotCoveringTheWholeRow +
    numberOfOneCardContentComponentsNotCoveringTheWholeRow;

  if (
    positionForCampaignCarousel !== undefined &&
    positionForCampaignCarousel - spaceTakenByGridExtras - 1 ===
      currentProductIndex
  ) {
    contentIndexRef.current += 1;

    const productsForCarousel =
      products.length > carouselData.productsCount
        ? products.slice(0, carouselData.productsCount)
        : products;

    const enoughProductsAvailable = productsForCarousel.length >= slidesPerView;

    return enoughProductsAvailable ? (
      <CampaignCarousel
        campaignCarouselProductsData={campaignCarouselProductsData}
        currentProductIndex={currentProductIndex}
        isMobile={isMobile}
        nrOfProductsInRow={nrOfProductsInRow}
        pageType={pageType}
        positionForCampaignCarousel={positionForCampaignCarousel}
        PriceComponents={PriceComponents}
        products={productsForCarousel}
        selectedColumnsNumber={selectedColumnsNumber}
        slidesPerView={slidesPerView}
        strategy={
          carouselData.strategy as RecommendationsParametersStrategyEnum
        }
      />
    ) : null;
  }

  return null;
};

export const getOneCardContentComponent = (
  componentsData: OneCardContentComponentData[],
  productIndex: number,
  contentIndexRef: React.MutableRefObject<number>,
  numberOfSmallBanners: number,
  selectedColumnsNumber: number
): JSX.Element | null => {
  const nrOfProductsBetweenComponent = 6;
  const showContentAtThisPosition =
    (productIndex + 1 + numberOfSmallBanners) % nrOfProductsBetweenComponent ===
    0;
  const hasRemainingContent = contentIndexRef.current < componentsData.length;
  if (hasRemainingContent && showContentAtThisPosition) {
    const content = componentsData[contentIndexRef.current];
    contentIndexRef.current += 1;

    return content !== undefined ? (
      <OneCardContentComponent
        content={content}
        selectedColumnsNumber={selectedColumnsNumber}
      />
    ) : null;
  }

  return null;
};
