import type { RangeFacet } from "@xxl/search-api";
import isString from "lodash/isString";
import {
  getHistoryStateValue,
  replaceHistoryState,
} from "../../../../utils/History";
import {
  PRICE_FACET_ATTRIBUTE_NAME,
  PRICE_VALUE_FACET_ATTRIBUTE_NAME,
  STYLE_FACET_ATTRIBUTE_NAME,
} from "../../../Search/Constants";

const OPEN_FILTER_ACCORDIONS_KEY = "openFilterAccordions";
export const colorsThatNeedBorder = ["#ffffff"];

export const shouldItemBeDisplayed = (
  filterSearchQuery: string,
  item?: string | number | boolean
): boolean =>
  isString(item)
    ? item.toLowerCase().includes(filterSearchQuery.toLowerCase())
    : true;

export const updateExpandedHistory = (
  isExpanded: boolean,
  id: string
): void => {
  const openFilterAccordions = new Set(
    getHistoryStateValue(OPEN_FILTER_ACCORDIONS_KEY)
  );
  openFilterAccordions[isExpanded ? "add" : "delete"](id);
  replaceHistoryState({
    openFilterAccordions: Array.from(openFilterAccordions),
  });
};

export const getExpandedStateHistoryValue = (id: string): boolean =>
  getHistoryStateValue(OPEN_FILTER_ACCORDIONS_KEY)?.includes(id) ?? false;

type ValidFilter = {
  max: number;
  min: number;
  name: string;
  selectedMax?: number;
  selectedMin?: number;
};
export const verifyRangeFilter = (filter: RangeFacet): ValidFilter => {
  const { max, min, name, selectedMax, selectedMin } = filter;

  if (typeof max !== "number" || typeof min !== "number") {
    throw new Error(
      `The types of range values "min" and "max" should be "number". Current types are min: ${typeof min} and max: ${typeof max}.`
    );
  }

  if (typeof selectedMin !== "number") {
    if (selectedMin !== undefined) {
      throw new Error(
        `Range value selectedMin must be number or undefined. The current type is ${typeof selectedMin}`
      );
    }
  }

  if (typeof selectedMax !== "number") {
    if (selectedMax !== undefined) {
      throw new Error(
        `Range value selectedMax must be number or undefined. The current type is ${typeof selectedMax}`
      );
    }
  }

  if (name === undefined) {
    throw new Error("name is undefined.");
  }

  return { max, min, name, selectedMax, selectedMin };
};

export const initiallyExpandedFilterIdSuffixes = [
  `-${PRICE_VALUE_FACET_ATTRIBUTE_NAME}`,
  `-${PRICE_FACET_ATTRIBUTE_NAME}`,
  `-${STYLE_FACET_ATTRIBUTE_NAME}`,
];

export const isMatchingExpansionFilterSuffix = (id: string): boolean =>
  initiallyExpandedFilterIdSuffixes.some((suffix) => id.endsWith(suffix));

export const shouldBeExpandedInitially = (id: string): boolean =>
  getExpandedStateHistoryValue(id) || isMatchingExpansionFilterSuffix(id);
