import React from "react";
import { Link } from "./SearchSuggestions.styled";

type SearchSuggestionProps = {
  suggestion: string;
  useSearchV2: boolean;
};

export const SearchSuggestion: React.FunctionComponent<
  SearchSuggestionProps
> = ({ suggestion, useSearchV2 }) => {
  const href = `/search${useSearchV2 ? "-v2" : ""}?query=${suggestion}&sort=relevance`;
  return <Link href={href}>{suggestion}</Link>;
};
