import type { FunctionComponent, PropsWithChildren } from "react";
import React from "react";
import {
  Accordion as StyledAccordion,
  AccordionDetails,
} from "./Accordion.styled";
import { FilterItem } from "../../Filter/FilterBar/FilterItem";

type AccordionProps = {
  className?: string;
  id: string;
  isFetchingNewSearchResult: boolean;
  isRangeFilter?: boolean;
  label: string;
  totalHits: number;
};

export const AccordionSimple: FunctionComponent<
  PropsWithChildren<AccordionProps>
> = ({
  children,
  className,
  label,
  id,
  isFetchingNewSearchResult,
  isRangeFilter = false,
  totalHits,
}) => (
  <FilterItem
    isFetchingNewSearchResult={isFetchingNewSearchResult}
    name={label}
    id={id}
    isRangeFilter={isRangeFilter}
    totalHits={totalHits}
  >
    <StyledAccordion
      className={className}
      data-testid={`filter-${id.toLowerCase()}`}
    >
      <AccordionDetails>{children}</AccordionDetails>
    </StyledAccordion>
  </FilterItem>
);
