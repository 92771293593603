import React from "react";
import {
  Heading,
  OneCardContainer,
  ImageWrapper,
  Label,
  TextWrapper,
  Title,
  LinkText,
  Link,
} from "./OneCardContentComponent.styled";
import type { OneCardContentComponent as OneCardContentComponentData } from "@xxl/content-api";
import { ArrowRight } from "@xxl/icons";
import useMediaQuery from "@mui/material/useMediaQuery";
import { mobileMediaQuery } from "../../../utils/xxl-screen";
import Breakpoints from "../../../styles/breakpoints.config";
import { handleImageError } from "../../../utils/xxl-image";

type OneCardContentComponentProps = {
  content: OneCardContentComponentData;
  selectedColumnsNumber: number;
};

export const ONE_CARD_CONTENT_COMPONENT_TEST_ID = "one-card-content-component";
const MAX_MOBILE_WIDTH = 767;

export const OneCardContentComponent: React.FunctionComponent<
  OneCardContentComponentProps
> = ({ content, selectedColumnsNumber }) => {
  const isMobile = useMediaQuery(mobileMediaQuery);
  const { colorTheme, contentImage, label, link, title } = content;
  const imageUrlPrefix = `${contentImage.url}?auto=format&fit=crop`;

  return (
    <OneCardContainer
      columnAmount={selectedColumnsNumber}
      data-testid={ONE_CARD_CONTENT_COMPONENT_TEST_ID}
    >
      <Link href={link.url}>
        <Heading color={colorTheme.font} backgroundColor={colorTheme.value}>
          <TextWrapper>
            <Label
              color={colorTheme.value}
              backgroundColor={colorTheme.font}
              columnAmount={selectedColumnsNumber}
            >
              {label}
            </Label>
            <Title columnAmount={selectedColumnsNumber}>{title}</Title>
          </TextWrapper>
          <LinkText>
            {link.displayName}
            <ArrowRight fontSize={isMobile ? 15 : 20} color={colorTheme.font} />
          </LinkText>
        </Heading>
        <ImageWrapper>
          <picture data-private={true}>
            <source
              media={`(max-width: ${MAX_MOBILE_WIDTH}px)`}
              srcSet={`${imageUrlPrefix}&w=400&h=180`}
            />
            <source
              media={`(min-width: ${Breakpoints.tablet}px)`}
              srcSet={`${imageUrlPrefix}&w=324&h=550`}
            />
            <img
              src={`${imageUrlPrefix}&w=400&h=180`}
              alt={contentImage.altText}
              onError={handleImageError}
            />
          </picture>
        </ImageWrapper>
      </Link>
    </OneCardContainer>
  );
};
