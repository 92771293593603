import React from "react";
import { useTranslations } from "../../../../contexts/Translations/TranslationsContext";
import {
  ButtonContainer,
  FooterDesktop,
  SaveButtonContainer,
  SaveSection,
} from "./styles";
import { XxlButton } from "../../../Common/XxlButton";

type Props = {
  isMobile: boolean;
  handleClose: () => void;
  handleCancelClick: () => void;
};
export const DialogFooter = ({
  isMobile,
  handleClose,
  handleCancelClick,
}: Props) => {
  const { t } = useTranslations();

  if (!isMobile) {
    return (
      <SaveSection>
        <FooterDesktop>
          <ButtonContainer>
            <XxlButton
              size="small"
              onClick={handleCancelClick}
              type="button"
              variant="outlined"
            >
              {t("general.cancel")}
            </XxlButton>
            <XxlButton
              size="small"
              onClick={handleClose}
              type="button"
              variant="secondary"
            >
              {t("general.save")}
            </XxlButton>
          </ButtonContainer>
        </FooterDesktop>
      </SaveSection>
    );
  }
  return (
    <SaveSection>
      <SaveButtonContainer>
        <XxlButton onClick={handleClose} type="button" variant="secondary">
          {t("general.save")}
        </XxlButton>
      </SaveButtonContainer>
    </SaveSection>
  );
};
