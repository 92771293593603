import { Checkbox } from "@mui/material";
import * as React from "react";
import { StyledFormControlLabel } from "../Checkbox.styled";
import { Circle, CircleSelected, SpecialColor } from "./Circle.styled";
import { isNotNullOrUndefined } from "@xxl/common-utils";

type ColorCheckboxProps = {
  checked: boolean;
  color: string | null;
  label: React.ReactNode;
  shouldUseBorder: boolean;
};
const ColorCheckbox: React.FunctionComponent<ColorCheckboxProps> = ({
  checked,
  color,
  label,
  shouldUseBorder,
}) => {
  const colorString = isNotNullOrUndefined(color) ? color : SpecialColor.Multi;

  return (
    <StyledFormControlLabel
      control={
        <Checkbox
          icon={<Circle color={colorString} />}
          checkedIcon={<CircleSelected color={colorString} />}
          sx={{
            padding: 0,
            marginLeft: "3px",
            border: shouldUseBorder
              ? "1px solid var(--color-xxl-gray)"
              : "none",
          }}
          {...{ checked }}
        />
      }
      {...{ label }}
      sx={{
        marginLeft: 0,
        marginTop: "3px",
      }}
    />
  );
};

export { ColorCheckbox };
