import type { CategoryData } from "@xxl/frontend-api";

export type SelectorCategory = {
  code: string;
  name: string;
  subCategories: SelectorCategory[];
  productCount?: number;
};

const createSelectorCategory = ({
  code,
  name,
  subCategories,
}: CategoryData): SelectorCategory => {
  if (code === undefined) {
    throw Error(`Category is missing required property "code"`);
  }

  if (name === undefined) {
    throw Error(`Category is missing required property "name"`);
  }

  const convertedSubCategories =
    subCategories !== undefined
      ? subCategories.map((category) => createSelectorCategory(category))
      : [];

  return {
    code,
    name,
    subCategories: convertedSubCategories,
  };
};

export const categoriesToSelectorCategories = (
  categories: CategoryData[]
): SelectorCategory[] =>
  categories.map((category) => createSelectorCategory(category));

export const defaultSlidesPerView = 3;
export const getSlidesPerView = (contentLength: number): number =>
  Math.min(contentLength, defaultSlidesPerView);
