import { GridSwitchItemStyled, GridSwitchStyled } from "./FilterBar.styled";
import React from "react";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import {
  mobileMediaQuery,
  mobileAndTabletNotHorizontalMediaQuery,
} from "../../../utils/xxl-screen";
import { GridFourIcon, GridOneIcon } from "@xxl/icons";
import { GRID_COLUMNS } from "./constants";
import { dispatchEvent } from "../../../utils/xxl-event";
import { FilterArticleCount } from "react-app/src/components/Filter/FilterBar/FilterArticleCount";

export const FilterGridSwitch = ({
  numberOfProducts,
  selectedColumnsNumber,
}: {
  numberOfProducts: number;
  selectedColumnsNumber: number;
}) => {
  const { t } = useTranslations();
  const isMobile = useMediaQuery(mobileMediaQuery);
  const isTablet = useMediaQuery(mobileAndTabletNotHorizontalMediaQuery);

  const onSelectGrid = (columnsNr: number) =>
    dispatchEvent("XXL_SET_SELECTED_COLUMNS_NUMBER", {
      selectedColumnsNumber: columnsNr,
    });

  const isActive = (gridNumber: number) => selectedColumnsNumber === gridNumber;

  const IconGridSwitch = () => {
    const fewerProductsIconValue = isMobile
      ? GRID_COLUMNS["one"]
      : GRID_COLUMNS["two"];

    const moreProductsIconValue = isMobile
      ? GRID_COLUMNS["two"]
      : GRID_COLUMNS["four"];
    return (
      <GridSwitchStyled>
        <GridSwitchItemStyled
          isActive={isActive(fewerProductsIconValue)}
          onClick={() => onSelectGrid(fewerProductsIconValue)}
          role="button"
        >
          <GridOneIcon />
        </GridSwitchItemStyled>
        <GridSwitchItemStyled
          isActive={isActive(moreProductsIconValue)}
          onClick={() => onSelectGrid(moreProductsIconValue)}
          role="button"
        >
          <GridFourIcon />
        </GridSwitchItemStyled>
      </GridSwitchStyled>
    );
  };

  const GridSwitchDesktop = () => (
    <>
      <GridSwitchItemStyled
        isActive={selectedColumnsNumber === GRID_COLUMNS["four"]}
        onClick={() => onSelectGrid(GRID_COLUMNS["four"])}
        role="button"
      >
        {GRID_COLUMNS["four"]}
      </GridSwitchItemStyled>
      <span>|</span>
      <GridSwitchItemStyled
        isActive={selectedColumnsNumber === GRID_COLUMNS["six"]}
        onClick={() => onSelectGrid(GRID_COLUMNS["six"])}
        role="button"
      >
        {GRID_COLUMNS["six"]}
      </GridSwitchItemStyled>
    </>
  );

  return (
    <GridSwitchStyled>
      {isMobile || isTablet ? (
        <>
          {isMobile ? (
            <FilterArticleCount numberOfProducts={numberOfProducts} />
          ) : null}
          <IconGridSwitch />
        </>
      ) : (
        <>
          <span>{t("filters.see")}</span>
          <GridSwitchDesktop />
        </>
      )}
    </GridSwitchStyled>
  );
};
