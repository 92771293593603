import React from "react";
import { useTranslations } from "../../../../contexts/Translations/TranslationsContext";
import { StyledCheckbox } from "../../../../styled";
import { XxlStack } from "../../../Common/XxlStack";
import { getFontFamily, type StoreWithId } from "./helpers";

type Props = {
  computedStores: StoreWithId[];
  isChecked: boolean;
  onStoreSelect: (ids: string[]) => void;
};

export const CheckboxAllStores = ({ onStoreSelect, isChecked }: Props) => {
  const { t } = useTranslations();
  const fontFamily = getFontFamily(isChecked);
  return (
    <XxlStack my={"24px"}>
      <StyledCheckbox
        checked={isChecked}
        handleChange={() => onStoreSelect([])}
        label={t("storefinder.warehouses.all.stores.checkbox.label")}
        id={t("storefinder.warehouses.all.stores.checkbox.label")}
        style={{ fontFamily }}
      />
    </XxlStack>
  );
};
