import { Drawer } from "@mui/material";
import type { SortOrderData } from "@xxl/product-search-api";
import type { ReactNode } from "react";
import React from "react";
import stringFormat from "string-format";
import { useSharedData } from "../../../contexts/SharedData";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { xxlTheme } from "../../../styles/xxl-theme";
import { removeStickyFilter } from "../../Filter/FilterHelper";
import {
  TOGGLE_FILTER_DRAWER,
  UPDATE_DRAWER_FILTER_SELECTED,
  useFilterContext,
} from "../../Filter/FilterState";
import { LegacySortItems } from "../../Sort/LegacySortItems";
import { SortItem } from "../../Sort/SortItem";
import {
  ClearButton,
  CloseIconStyled,
  FilterDrawerContainer,
  FilterDrawerFooter,
  FilterDrawerHeader,
  FilterDrawerHeadline,
  FilterDrawerSection,
  FilterDrawerSortList,
  FilterDrawerTopWrapper,
  FilterDrawerWrapper,
  FilterHeading,
  ShowProductsButton,
} from "./FilterDrawer.styled";

export const FilterDrawer = ({
  children,
  numberOfSelectedFilters,
  onChangeSortOption,
  onRemoveAllFilters,
  selectedSort,
  shouldAutomaticallyScrollToFilterBar = true,
  sortOptions,
  totalHits,
}: {
  children: ReactNode;
  numberOfSelectedFilters: number;
  onRemoveAllFilters: () => void;
  selectedSort: SortOrderData["selected"] | null;
  sortOptions: SortOrderData["options"] | null;
  totalHits: number;
  onChangeSortOption?: (value: string) => void;
  shouldAutomaticallyScrollToFilterBar?: boolean;
}) => {
  const { t } = useTranslations();
  const { state: filterState, dispatch: filterDispatch } = useFilterContext();
  const { featureToggles } = useSharedData().data;
  const { toggle_sticky_filter } = featureToggles;
  const { filterSelected, isFilterDrawerOpen } = filterState;
  const { zIndex } = xxlTheme;

  const closeFilterDrawer = (): void => {
    filterDispatch({
      type: TOGGLE_FILTER_DRAWER,
      payload: false,
    });

    if (toggle_sticky_filter && filterSelected) {
      if (shouldAutomaticallyScrollToFilterBar) {
        removeStickyFilter();
      }

      if (isFilterDrawerOpen) {
        filterDispatch({
          type: UPDATE_DRAWER_FILTER_SELECTED,
          payload: false,
        });
      }
    }
  };

  const clearFilters = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    if (toggle_sticky_filter && !shouldAutomaticallyScrollToFilterBar) {
      removeStickyFilter();
    }
    onRemoveAllFilters();
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={isFilterDrawerOpen}
        onClose={closeFilterDrawer}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{ zIndex: zIndex.modal }}
      >
        <FilterDrawerWrapper>
          <FilterDrawerTopWrapper>
            <FilterDrawerHeader>
              <FilterDrawerHeadline>
                {t("filters.all.filters")}
              </FilterDrawerHeadline>
              <CloseIconStyled onClick={closeFilterDrawer} />
            </FilterDrawerHeader>
            <FilterDrawerContainer>
              <FilterDrawerSection>
                <FilterHeading>{t("filters.sort.by")}</FilterHeading>
                <FilterDrawerSortList role="list">
                  {sortOptions === null ? (
                    <LegacySortItems idPrefix="filter-drawer_" />
                  ) : (
                    sortOptions.map(({ id: sortId = "", label = "" }) => (
                      <div key={sortId}>
                        <SortItem
                          shouldAutomaticallyScrollToFilterBar={
                            shouldAutomaticallyScrollToFilterBar
                          }
                          idPrefix="filter-drawer_"
                          isSelected={sortId === selectedSort}
                          name={label}
                          onClick={onChangeSortOption}
                          value={sortId}
                        />
                      </div>
                    ))
                  )}
                </FilterDrawerSortList>
              </FilterDrawerSection>
              <FilterDrawerSection>
                <FilterHeading>{t("filters.button.text")}</FilterHeading>
                {children}
              </FilterDrawerSection>
            </FilterDrawerContainer>
            <FilterDrawerFooter>
              {numberOfSelectedFilters > 0 ? (
                <ClearButton
                  data-testid="mobile-filter-clear-button"
                  onClick={clearFilters}
                >
                  {t("filters.remove.all")}
                </ClearButton>
              ) : null}
              <ShowProductsButton
                data-testid="mobile-filter-close-button"
                onClick={closeFilterDrawer}
              >
                {stringFormat(
                  t("filters.total.items.show"),
                  totalHits.toString()
                )}
              </ShowProductsButton>
            </FilterDrawerFooter>
          </FilterDrawerTopWrapper>
        </FilterDrawerWrapper>
      </Drawer>
    </>
  );
};
