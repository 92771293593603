/* TODO: Remove in XD-13966 */
import React, { type PropsWithChildren } from "react";
import { FilterSortListStyled } from "./FilterBar.styled";
import { FilterItem } from "./FilterItem";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { LegacySortItems } from "../../Sort/LegacySortItems";
import { useSearchContext } from "../../Search/SearchState";

type FilterItemProps = {
  id: string;
};

export const LegacyFilterSortItems = ({
  id,
}: PropsWithChildren<FilterItemProps>) => {
  const { t } = useTranslations();
  const {
    state: { isFetchingNewSearchResult, searchData },
  } = useSearchContext();
  const { results } = searchData ?? {};
  const { count: totalHits = 0 } = results ?? {};

  return (
    <FilterItem
      isFetchingNewSearchResult={isFetchingNewSearchResult}
      name={t("filters.sort.by")}
      id={id}
      totalHits={totalHits}
    >
      <FilterSortListStyled role="list">
        <LegacySortItems idPrefix="filter-bar_" />
      </FilterSortListStyled>
    </FilterItem>
  );
};
