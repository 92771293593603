import type { SortOrderData } from "@xxl/product-search-api";
import React, { type PropsWithChildren } from "react";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { SortItem } from "../../Sort/SortItem";
import { FilterSortListStyled } from "./FilterBar.styled";
import { FilterItem } from "./FilterItem";

type FilterItemProps = {
  shouldAutomaticallyScrollToFilterBar: boolean;
  id: string;
  onChangeSortOption?: (value: string) => void;
  selectedSort: SortOrderData["selected"] | null;
  sortOptions: SortOrderData["options"];
  totalHits: number;
};

export const FilterSortItems = ({
  shouldAutomaticallyScrollToFilterBar = true,
  id,
  onChangeSortOption,
  selectedSort,
  sortOptions,
  totalHits,
}: PropsWithChildren<FilterItemProps>) => {
  const { t } = useTranslations();

  return (
    <FilterItem
      isFetchingNewSearchResult={false}
      name={t("filters.sort.by")}
      id={id}
      totalHits={totalHits}
    >
      <FilterSortListStyled role="list">
        {sortOptions.map(({ id: sortId = "", label = "" }) => (
          <SortItem
            shouldAutomaticallyScrollToFilterBar={
              shouldAutomaticallyScrollToFilterBar
            }
            idPrefix="filter-bar_"
            isSelected={sortId === selectedSort}
            key={sortId}
            name={label}
            onClick={onChangeSortOption}
            value={sortId}
          />
        ))}
      </FilterSortListStyled>
    </FilterItem>
  );
};
