import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import React, { memo } from "react";
import {
  COLOR_FACET_ATTRIBUTE_NAME,
  STYLE_FACET_ATTRIBUTE_NAME,
} from "react-app/src/components/Search/Constants";
import { useTracking } from "react-app/src/contexts/Tracking";
import { mobileAndTabletNotHorizontalMediaQuery } from "../../../../../../..//utils/xxl-screen";
import { ENTER_KEY, SPACE_BAR_KEY } from "../../../../../../../constants";
import { useSharedData } from "../../../../../../../contexts/SharedData";
import { StyledCheckbox } from "../../../../../../../styled";
import { ColorCheckbox } from "../../../../../../../styled/Checkbox/ColorCheckbox";
import { xxlTheme } from "../../../../../../../styles/xxl-theme";
import { scrollToFilterBar } from "../../../../../../../utils/xxl-scroll-to-filter-bar";
import { removeStickyFilter } from "../../../../../FilterHelper";
import {
  UPDATE_DRAWER_FILTER_SELECTED,
  useFilterContext,
} from "../../../../../FilterState";
import { trackFilterChange } from "../../../../../tracking-helper";
import { colorsThatNeedBorder } from "../../../filter-accordion-helper";
import { CountNumber } from "../DistinctFilterAccordion.styled";
import type { OnChangeFilterProps } from "../types";
import { ColorItem, Item, Link } from "./FilterItem.styled";

const { colors } = xxlTheme;

type FilterItemProps = {
  attributeName: string;
  category?: string;
  color?: string | null;
  count?: number;
  href?: string;
  isSelected: boolean;
  item: string | number | boolean;
  label: string;
  onChangeFilter: ({ action, id, values }: OnChangeFilterProps) => void;
  searchQuery?: string;
  shouldAutomaticallyScrollToFilterBar?: boolean;
};

const _FilterItem = ({
  attributeName,
  category,
  color,
  count,
  href,
  isSelected,
  item,
  label,
  onChangeFilter,
  searchQuery,
  shouldAutomaticallyScrollToFilterBar = true,
}: FilterItemProps) => {
  const { dispatch: filterDispatch, state: filterState } = useFilterContext();
  const { isStickyFilterBar } = filterState;
  const isMobileOrTablet = useMediaQuery(
    mobileAndTabletNotHorizontalMediaQuery
  );
  const { featureToggles } = useSharedData().data;
  const { toggle_sticky_filter } = featureToggles;
  const trackers = useTracking();

  const selectFilterItem = (): void => {
    if (toggle_sticky_filter) {
      if (isMobileOrTablet) {
        if (shouldAutomaticallyScrollToFilterBar) {
          scrollToFilterBar();
        }
      } else if (isStickyFilterBar) {
        removeStickyFilter();
      }
      filterDispatch({
        type: UPDATE_DRAWER_FILTER_SELECTED,
        payload: true,
      });
    }

    onChangeFilter({
      action: isSelected ? "remove" : "add",
      id: attributeName,
      longTailUrl: null,
      values: [item.toString()],
    });

    trackFilterChange({
      attributeName,
      category,
      isFilterActive: !isSelected,
      items: [item],
      searchQuery,
      trackers,
    });
  };
  const style = {
    top: "3px",
    ...(isSelected
      ? {
          backgroundColor: colors.xxlGreen,
          borderColor: colors.xxlGreen,
        }
      : { backgroundColor: "transparent" }),
  };

  const shouldUseBorder = colorsThatNeedBorder.some(
    (borderColor) => borderColor.toLowerCase() === color?.toLocaleLowerCase()
  );

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault();
    selectFilterItem();
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLAnchorElement>): void => {
    // Make anchor behave like a <button>
    switch (event.key) {
      case ENTER_KEY:
      case SPACE_BAR_KEY:
        event.preventDefault();
        selectFilterItem();
        break;
    }
  };

  return (
    <Link {...{ href, onClick, onKeyDown }}>
      {[COLOR_FACET_ATTRIBUTE_NAME, STYLE_FACET_ATTRIBUTE_NAME].includes(
        attributeName
      ) ? (
        <ColorCheckbox
          label={
            <ColorItem>
              {label}
              {count !== undefined && <CountNumber> ({count})</CountNumber>}
            </ColorItem>
          }
          checked={isSelected}
          color={color ?? null}
          shouldUseBorder={shouldUseBorder}
        />
      ) : (
        <StyledCheckbox
          name={attributeName}
          checked={isSelected}
          label={
            <Item>
              {label}
              {count !== undefined && <CountNumber> ({count})</CountNumber>}
            </Item>
          }
          style={style}
        />
      )}
    </Link>
  );
};

export const FilterItem = memo(_FilterItem);
