import type { RecommendationsParametersStrategyEnum } from "@xxl/recommendations-api";
import type { ProductData as ProductDataSearch } from "@xxl/search-api";
import type { ReactElement } from "react";
import React from "react";
import type { PageType } from "../../constants";
import type { ProductMetaData } from "../../global";
import { Slider } from "../Common/Slider";
import { CarouselProductCard } from "../Product/CarouselProductCard";
import type { PriceWithLabelsProps } from "../Product/PriceWithLabels/PriceWithLabels";
import type { CombinedProductData } from "../Product/product-helper";
import {
  CarouselContainer,
  RecommendationsWrapper,
  Title,
} from "./ProductListGridExtras.styled";

export const RECOMMENDATIONS_CAROUSEL_TEST_ID =
  "products-recommendations-carousel";

type ProductsRecommendationsCarouselProps = {
  carouselType: RecommendationsParametersStrategyEnum;
  currentProductIndex: number;
  isMobile: boolean;
  nrOfProductsInRow: number;
  pageType: PageType;
  PriceComponents: ReactElement<PriceWithLabelsProps>[];
  products: CombinedProductData[];
  selectedColumnsNumber: number;
  slidersPerView: number;
  title?: string;
};

export const ProductsRecommendationsCarousel: React.FunctionComponent<
  ProductsRecommendationsCarouselProps
> = ({
  carouselType,
  currentProductIndex,
  isMobile,
  nrOfProductsInRow,
  pageType,
  PriceComponents,
  products,
  selectedColumnsNumber,
  slidersPerView,
  title,
}) => {
  return (
    <RecommendationsWrapper
      data-testid={RECOMMENDATIONS_CAROUSEL_TEST_ID}
      key={`${currentProductIndex}-carousel`}
      columnAmount={nrOfProductsInRow}
      carouselType={carouselType}
    >
      <Title>{title}</Title>
      <CarouselContainer>
        <Slider
          items={products.map((product, index) => {
            const productMetaData: ProductMetaData = {
              list: "carousel",
              pageType,
              position: index,
            };
            return (
              <CarouselProductCard
                key={product.code}
                PriceComponent={PriceComponents[index]}
                product={product as ProductDataSearch}
                productMetaData={productMetaData}
                selectedColumnsNumber={selectedColumnsNumber}
              />
            );
          })}
          mode="snap"
          slidesConfig={{
            perView: slidersPerView,
            spacing: 12,
          }}
          navigation={isMobile ? "DOTS" : "DOTS_AND_ARROWS"}
          dotsContainerStyle={
            isMobile ? { marginTop: 0, marginBottom: 12 } : undefined
          }
          navigationMargin={{ right: -20, left: -20 }}
        />
      </CarouselContainer>
    </RecommendationsWrapper>
  );
};
