import { useMediaQuery } from "@mui/material";
import { hasValue, isNotNull } from "@xxl/common-utils";
import type { LongTailData } from "@xxl/frontend-api";
import isEmpty from "lodash/isEmpty";
import { memo, useEffect, useState } from "react";
import { useSharedData } from "../../../../../../../src/contexts/SharedData";
import { useTranslations } from "../../../../../../contexts/Translations/TranslationsContext";
import type {
  DistinctFacetItem,
  Filter,
} from "../../../../../../utils/data-types";
import { mobileMediaQuery } from "../../../../../../utils/xxl-screen";
import { AccordionList } from "../../../../../Common/Accordion/AccordionList";
import { AccordionListSimple } from "../../../../../Common/Accordion/AccordionListSimple";
import { EmptySearchResult } from "../../../../../Search/EmptySearchResult";
import {
  getDistinctFilterItemHref,
  isAnyFilterSelected,
  isDistinctFacetFilterItemSelected,
} from "../../../../FilterHelper";
import { MAX_NUMBER_OF_FILTERS } from "../../../Filters/constants";
import {
  isMatchingExpansionFilterSuffix,
  shouldBeExpandedInitially,
  shouldItemBeDisplayed,
  updateExpandedHistory,
} from "../../filter-accordion-helper";
import { FilterItem } from "./FilterItem";
import { FilterSearch } from "./FilterSearch";
import type { OnChangeFilterProps } from "./types";

type FilterAccordionProps = {
  attributeName: string;
  category: string;
  filterItems?: (DistinctFacetItem & { label?: string })[];
  filterName: string;
  isClearFiltersClicked: boolean;
  isFetchingNewSearchResult?: boolean;
  isFilterBar?: boolean;
  isSearchable?: boolean;
  longTailFacets: LongTailData[] | null;
  longTailPattern: string | null;
  onChangeFilter: ({ action, id, values }: OnChangeFilterProps) => void;
  searchQuery?: string;
  selectedFilters: Filter[];
  totalHits: number;

  shouldAutomaticallyScrollToFilterBar?: boolean;
};

const _DistinctFilterAccordion: React.FunctionComponent<
  FilterAccordionProps
> = ({
  attributeName,
  category,
  filterItems,
  filterName,
  isClearFiltersClicked,
  isFetchingNewSearchResult = false,
  isFilterBar = false,
  isSearchable = false,
  longTailFacets,
  longTailPattern,
  onChangeFilter,
  searchQuery,
  selectedFilters,
  shouldAutomaticallyScrollToFilterBar,
  totalHits,
}) => {
  const isMobile = useMediaQuery(mobileMediaQuery);
  const { t } = useTranslations();
  const [filterSearchQuery, setFilterSearchQuery] = useState("");
  const handleInputChange = (query: string): void => {
    setFilterSearchQuery(query);
  };
  const [expanded, setExpanded] = useState(false);
  const { configuration } = useSharedData().data;
  const id = `${category}-${attributeName}`;

  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    isExpanded: boolean
  ): void => {
    setExpanded(isExpanded);
    updateExpandedHistory(isExpanded, id);
  };
  const [initiallyExpanded, setInitiallyExpanded] = useState<boolean | null>(
    null
  );

  useEffect(() => {
    if (initiallyExpanded !== null) {
      return;
    }

    setInitiallyExpanded(
      isAnyFilterSelected(selectedFilters, attributeName) ||
        (!isMobile && shouldBeExpandedInitially(id))
    );
  }, [
    expanded,
    id,
    attributeName,
    isMobile,
    selectedFilters,
    initiallyExpanded,
  ]);

  useEffect(() => {
    if (initiallyExpanded === null) {
      return;
    }

    setExpanded(initiallyExpanded);
  }, [initiallyExpanded]);

  useEffect(() => {
    const shouldCollapse = !isMatchingExpansionFilterSuffix(id);
    if (isClearFiltersClicked && shouldCollapse) {
      setExpanded(false);
    }
  }, [id, isClearFiltersClicked]);

  const createFilters = () => {
    const filterItemsToDisplay =
      filterItems?.filter(({ item }) => {
        return shouldItemBeDisplayed(filterSearchQuery, item);
      }) ?? [];
    const updatedFilters: React.ReactElement[] = [];
    const hasFilters = !isEmpty(filterItemsToDisplay);

    if (isSearchable) {
      updatedFilters.push(
        <FilterSearch
          key="search-wrapper"
          onChange={handleInputChange}
          placeholder={t("filters.items.search.brands.placeholder")}
        />
      );
    }
    if (hasFilters) {
      updatedFilters.push(
        ...filterItemsToDisplay.flatMap(({ color, count, label, item }) => {
          if (item === undefined) {
            return [];
          }

          const isSelected = isDistinctFacetFilterItemSelected(
            attributeName,
            selectedFilters,
            item
          );
          const value = item.toString();
          const longTailUrlPath =
            isNotNull(selectedFilters) &&
            hasValue(longTailFacets) &&
            hasValue(longTailPattern)
              ? getDistinctFilterItemHref(
                  configuration.frontendApi.basePath,
                  selectedFilters,
                  isSelected,
                  attributeName,
                  value,
                  longTailFacets,
                  longTailPattern
                )
              : undefined;
          const longTailLink =
            hasValue(longTailUrlPath) &&
            longTailUrlPath.shouldFacetBeIndexed(attributeName)
              ? longTailUrlPath.getUpdatedLongTailPath()
              : undefined;

          return (
            <FilterItem
              attributeName={attributeName}
              category={category}
              key={`${attributeName}-${value}`}
              href={longTailLink}
              label={label ?? value}
              item={value}
              isSelected={isSelected}
              color={color}
              count={count}
              onChangeFilter={onChangeFilter}
              searchQuery={searchQuery}
              shouldAutomaticallyScrollToFilterBar={
                shouldAutomaticallyScrollToFilterBar
              }
            />
          );
        })
      );
    } else {
      updatedFilters.push(
        <EmptySearchResult
          key="empty-search-result"
          heading={t("filters.no.result.heading")}
          text={t("filters.items.search.no.result")}
        />
      );
    }
    return updatedFilters;
  };

  const filters = createFilters();

  if (isFilterBar) {
    return (
      <div data-testid="category-filter">
        <AccordionListSimple
          id={attributeName}
          isFetchingNewSearchResult={isFetchingNewSearchResult}
          label={filterName}
          onChange={handleChange}
          listItems={filters}
          totalHits={totalHits}
        />
      </div>
    );
  }

  return (
    <div style={{ overflow: "hidden" }}>
      <AccordionList
        expanded={expanded}
        id={attributeName}
        label={filterName}
        onChange={handleChange}
        numberOfVisibleListItems={MAX_NUMBER_OF_FILTERS}
      >
        {filters}
      </AccordionList>
    </div>
  );
};

export const DistinctFilterAccordion = memo(_DistinctFilterAccordion);
