import React from "react";
import type { PropsWithChildren } from "react";
import { EditButton } from "./AvailabilitySelector.styled";

export const EditSelectedStoresButton = ({
  children,
  onClick,
}: PropsWithChildren<{ onClick: () => void }>) => (
  <EditButton data-testid="edit-store-selector" onClick={onClick}>
    {children}
  </EditButton>
);
