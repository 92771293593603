import * as React from "react";
import { SearchInput, SearchWrapper } from "./FilterSearch.styled";
import { SearchIcon } from "@xxl/icons";

type FilterSearchProps = {
  onChange: (query: string) => void;
  placeholder: string;
};

const FilterSearch = ({ onChange, placeholder }: FilterSearchProps) => {
  const handleInputChange = ({
    currentTarget: { value },
  }: React.FormEvent<HTMLInputElement>): void => {
    onChange(value.trim());
  };

  return (
    <SearchWrapper>
      <SearchIcon fontSize={18} href="#search-icon" xlinkHref="#search-icon" />
      <SearchInput
        type="search"
        onChange={handleInputChange}
        {...{ placeholder }}
      />
    </SearchWrapper>
  );
};

export const MemoizedFilterSearch = React.memo(FilterSearch);
