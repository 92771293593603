import styled from "@emotion/styled/macro";
import { typographyToCss } from "../../../styles/helpers";
import { xxlTheme } from "../../../styles/xxl-theme";
const { spaces } = xxlTheme;

export const Label = styled.label`
  display: flex;
  justify-content: space-between;
  margin: ${spaces.regular} 0 ${spaces.miniRegular};
`;

export const Value = styled.span`
  ${typographyToCss({
    fontSize: 12,
  })}
`;

type SliderContainerProps = {
  sidePadding: number;
};
export const SliderContainer = styled.div<SliderContainerProps>`
  padding: 0 ${({ sidePadding }) => sidePadding}px;
`;
