import { styled } from "@mui/material/styles";
import { xxlTheme } from "../../../styles/xxl-theme";

const { colors } = xxlTheme;
enum SpecialColor {
  Multi = "multi",
}
type CircleProps = {
  color: string | SpecialColor;
};
const Circle = styled("span")<CircleProps>`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  background: ${({ color }) =>
    color === SpecialColor.Multi
      ? "linear-gradient(235.01deg, #AD0000 20.57%, rgba(254, 158, 44, 0.87) 31.6%, rgba(238, 221, 67, 0.87) 44.16%, #0EAD00 57.09%, #003BAD 70.41%, #AA00AD 82.2%)"
      : ""};
`;

const CircleSelected = styled(Circle)`
  border: 2px solid white;
  outline: ${`3px solid ${colors.xxlGreen}`};
`;

export { Circle, CircleSelected, SpecialColor };
