import { PriceWithLabels } from "../Product/PriceWithLabels/PriceWithLabels";
import {
  type CombinedProductData,
  getProductType,
} from "../Product/product-helper";
import { DEFAULT_GRID_VALUE } from "../Product/constants";
import type { Translate } from "../../contexts/Translations/TranslationsContext";

type ProductListPriceProps = {
  product: CombinedProductData;
  selectedColumnsNumber?: number;
  t: Translate;
};

export const getProductListPrice = ({
  product,
  selectedColumnsNumber,
  t,
}: ProductListPriceProps) => {
  const { priceDisplay } = product;
  const productType = getProductType(product);
  return (
    <PriceWithLabels
      version={1}
      priceDisplay={priceDisplay}
      productType={productType}
      selectedColumnsNumber={selectedColumnsNumber ?? DEFAULT_GRID_VALUE}
      t={t}
    />
  );
};
