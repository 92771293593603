import type { Store } from "@xxl/content-api";
import type { StoreWithId, TRegionalStore } from "./DialogStoreSelect";

export const getStoreListItems = (stores: Store[]): StoreWithId[] =>
  stores.map((store) => ({
    name: store.name ?? "",
    id: store.id ?? "",
    regionIds: [],
  }));

export const computedRegionName = (storeName: string) => {
  return storeName.split(",")[0];
};

export const getStoreRegions = (stores: Store[]): TRegionalStore[] => {
  const results = [];
  for (const { id, name, seoSettings } of stores) {
    const region = seoSettings?.seoCity?.toLowerCase();
    if (region === undefined || id === undefined || name === undefined) {
      continue;
    }
    const knownRegionName = results.find(
      (s) => s.name === computedRegionName(name)
    );
    if (knownRegionName === undefined) {
      results.push({
        name: computedRegionName(name),
        regionIds: [id],
        id: `${region}-${id}`,
      });
    } else {
      knownRegionName.regionIds.push(id);
    }
  }

  return results.filter(({ regionIds: { length } }) => length > 1);
};
