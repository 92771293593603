import { NextImage } from "@/next-js-app/components/common/NextImage/NextImage";
import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { MQ, typographyToCss } from "../../styles/helpers";
import { xxlTheme } from "../../styles/xxl-theme";
import { GRID_COLUMNS } from "../Filter/FilterBar/constants";
import typography from "./ProductListingSmallBanner.typography";

type ItemProps = {
  textInverted: boolean;
  backgroundColor?: string;
};

const { colors, spaces } = xxlTheme;

export const Item = styled.div<ItemProps>(
  ({ textInverted, backgroundColor }) => css`
    color: ${textInverted ? colors.xxlWebBlack : colors.xxlWhite};
    background-color: ${backgroundColor};
    padding: 0;
    box-sizing: border-box;
    height: 100%;

    & * {
      box-sizing: border-box;
    }
  `
);

type ButtonProps = {
  textInverted: boolean;
};

export const StyledButton = styled.button<ButtonProps>(
  ({ textInverted }) => css`
    margin-top: auto;
    background-color: ${textInverted ? colors.xxlWebBlack : colors.xxlWhite};
    color: ${textInverted ? colors.xxlWhite : colors.xxlWebBlack};
    transition: 0.2s ease;
  `
);

export const StyledLink = styled.a`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0;
  color: currentColor;
  text-decoration: none;

  &:hover .button {
    background-color: ${colors.xxlGreen};
    color: ${colors.xxlWebBlack};
  }
`;

export const StyledImage = styled(NextImage)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
`;

export const StyledContentWrapper = styled.div`
  padding-top: calc(50% + 24px);
  width: 100%;
  position: relative;
  overflow: hidden;
  margin: 0;

  ${MQ("laptop")} {
    height: 100%;
    max-height: 100%;
    padding-top: 0;
  }
`;

export const StyledImageWrapper = styled.figure`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  margin: 0;
  z-index: 1;
`;

export const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${spaces.smallRegular};
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;

  ${MQ("laptop")} {
    padding: ${spaces.regular};
  }
`;

export const StyledLabel = styled.span`
  color: currentColor;
  ${typographyToCss(typography.label)};
  margin-bottom: ${spaces.mini};
`;

type GridColumnsProps = {
  columnAmount?: number;
};

export const StyledHeading = styled.span<GridColumnsProps>(
  ({ columnAmount }) => css`
    color: currentColor;
    ${typographyToCss(typography.heading)};
    margin: 0;
    text-align: left;

    ${MQ("laptop")} {
      ${columnAmount === undefined || columnAmount === GRID_COLUMNS.six
        ? `font-size: ${spaces.large};`
        : ""}
    }
  `
);
