import React, { memo } from "react";
import type { ChangeEvent } from "react";
import type { RangeValue } from "../../../../../Common/RangeSlider/RangeSlider.types";
import { useSearchContext } from "../../../../../Search/SearchState";
import { trackFilterChange } from "../../../../tracking-helper";
import { updateRangeFilter } from "../../../../FilterHelper";
import { useTracking } from "../../../../../../contexts/Tracking";
import { RangeFilterAccordion } from "./RangeFilterAccordion";

type FilterAccordionProps = {
  id: string;
  label: string;
  onChange?: (event: ChangeEvent<unknown>, isExpanded: boolean) => void;
};

type RangeFilterAccordionProps = {
  accordionProps: FilterAccordionProps;
  attributeName: string;
  category: string;
  isFilterBar?: boolean;
  range: { max: number; min: number };
  searchQuery: string;
  selectedMax?: number;
  selectedMin?: number;
};

const _LegacyRangeFilterAccordion: React.FunctionComponent<
  RangeFilterAccordionProps
> = ({
  accordionProps,
  attributeName,
  category,
  isFilterBar = false,
  range,
  searchQuery,
  selectedMax,
  selectedMin,
}) => {
  const {
    dispatch,
    state: { isFetchingNewSearchResult, searchData },
  } = useSearchContext();
  const { results } = searchData ?? {};
  const { count: totalHits = 0 } = results ?? {};

  const trackers = useTracking();

  const onUpdate = (value: RangeValue) => {
    const { min, max } = value;
    updateRangeFilter(attributeName, dispatch, value);

    trackFilterChange({
      attributeName,
      category,
      isFilterActive: true,
      items: [{ min, max }],
      searchQuery,
      trackers,
    });
  };

  return (
    <RangeFilterAccordion
      accordionProps={accordionProps}
      attributeName={attributeName}
      category={category}
      isFetchingNewSearchResult={isFetchingNewSearchResult}
      isFilterBar={isFilterBar}
      onUpdate={onUpdate}
      range={range}
      selectedMax={selectedMax}
      selectedMin={selectedMin}
      totalHits={totalHits}
    />
  );
};

export const LegacyRangeFilterAccordion = memo(_LegacyRangeFilterAccordion);
