import {
  PRICE_FACET_ATTRIBUTE_NAME,
  PRICE_VALUE_FACET_ATTRIBUTE_NAME,
} from "../../Search/Constants";
import type { FacetList } from "../../Search/SearchState";

/**
 * Remove "price" facet if "priceValue" exists.
 * This part should be removed once "price" has been removed from the search API.
 * See OPT-76.
 */
const conditionallyRemovePriceFacet = (facets: FacetList): FacetList => {
  const hasPriceValueFacet = facets.some(
    (f) => f.attributeName === PRICE_VALUE_FACET_ATTRIBUTE_NAME
  );
  if (hasPriceValueFacet) {
    return facets.filter((f) => f.attributeName !== PRICE_FACET_ATTRIBUTE_NAME);
  }
  return facets;
};

export { conditionallyRemovePriceFacet };
