import React, { memo, useState } from "react";
import { Accordion } from "../../../../../Common/Accordion/Accordion";
import { AccordionSimple } from "../../../../../Common/Accordion/AccordionSimple";
import { RangeSlider } from "../../../../../Common/RangeSlider/RangeSlider";
import type { RangeValue } from "../../../../../Common/RangeSlider/RangeSlider.types";
import {
  shouldBeExpandedInitially,
  updateExpandedHistory,
} from "../../filter-accordion-helper";

type FilterAccordionProps = {
  id: string;
  label: string;
  onChange?: (event: React.ChangeEvent<unknown>, isExpanded: boolean) => void;
};

type RangeFilterAccordionProps = {
  accordionProps: FilterAccordionProps;
  attributeName: string;
  category: string;
  isFetchingNewSearchResult?: boolean;
  onUpdate: (value: RangeValue) => void;
  range: { max: number; min: number };
  totalHits: number;
  isFilterBar?: boolean;
  selectedMax?: number;
  selectedMin?: number;
  shouldAutomaticallyScrollToFilterBar?: boolean;
};

const _RangeFilterAccordion: React.FunctionComponent<
  RangeFilterAccordionProps
> = ({
  accordionProps,
  attributeName,
  category,
  isFetchingNewSearchResult = false,
  isFilterBar = false,
  onUpdate,
  range,
  selectedMax,
  selectedMin,
  shouldAutomaticallyScrollToFilterBar = true,
  totalHits,
}) => {
  const { max, min } = range;
  const isAnyValueSelected =
    selectedMax !== undefined || selectedMin !== undefined;
  const id = `${category}-${attributeName}`;
  const initiallyExpanded = isAnyValueSelected || shouldBeExpandedInitially(id);
  const [expanded, setExpanded] = useState(initiallyExpanded);

  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    isExpanded: boolean
  ): void => {
    setExpanded(isExpanded);
    updateExpandedHistory(isExpanded, id);
  };

  return (
    <div
      data-testid="category-filter"
      style={{ overflow: isFilterBar ? "visible" : "hidden" }}
    >
      {isFilterBar ? (
        <AccordionSimple
          id={accordionProps.id}
          isFetchingNewSearchResult={isFetchingNewSearchResult}
          isRangeFilter={true}
          label={accordionProps.label}
          totalHits={totalHits}
        >
          <RangeSlider
            id={`${attributeName}-slider`}
            max={max}
            min={min}
            onUpdate={onUpdate}
            selectedMax={selectedMax}
            selectedMin={selectedMin}
            shouldAutomaticallyScrollToFilterBar={
              shouldAutomaticallyScrollToFilterBar
            }
          />
        </AccordionSimple>
      ) : (
        <Accordion
          id={accordionProps.id}
          label={accordionProps.label}
          onChange={handleChange}
          expanded={expanded}
        >
          <RangeSlider
            id={`${attributeName}-slider`}
            max={max}
            min={min}
            onUpdate={onUpdate}
            selectedMax={selectedMax}
            selectedMin={selectedMin}
            shouldAutomaticallyScrollToFilterBar={
              shouldAutomaticallyScrollToFilterBar
            }
          />
        </Accordion>
      )}
    </div>
  );
};

export const RangeFilterAccordion = memo(_RangeFilterAccordion);
