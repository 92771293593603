import { ArticlesCountStyled } from "./FilterBar.styled";
import stringFormat from "string-format";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";

export const FilterArticleCount = ({
  numberOfProducts,
}: {
  numberOfProducts: number;
}) => {
  const { t } = useTranslations();

  if (numberOfProducts === 0) {
    return null;
  }

  return (
    <ArticlesCountStyled>
      <span>
        {stringFormat(t("filters.total.items"), numberOfProducts.toString())}
      </span>
    </ArticlesCountStyled>
  );
};
