import type { ProductData as ProductDataSearch } from "@xxl/search-api";
import type { ReactElement } from "react";
import type { ProductMetaData } from "../../global";
import { saveCurrentScrollPosition } from "../Search/SearchScrollHelper";
import { useSearchContext } from "../Search/SearchState";
import {
  CardButtonIcons,
  CarouselProductCardContainer,
  ImageContainer,
  ProductInfoContainer,
  ProductLink,
  QuickShopButton,
  ReviewsWrapper,
} from "./Product.styled";
import { getOptionalRelativeHref, trackProductClick } from "./product-helper";

import { hasValue } from "@xxl/common-utils";
import { Cart } from "@xxl/icons";
import { useQuickshop } from "react-app/src/hooks/useQuickshop/useQuickshop";
import { useTracking } from "../../contexts/Tracking";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { FavoriteProductHeart } from "../Favorites/FavoriteProductHeart";
import type { PriceWithLabelsProps } from "./PriceWithLabels/PriceWithLabels";
import { ProductBasicInfo } from "./ProductBasicInfo";
import { ProductCardRibbons } from "./ProductCardRibbons";
import { ProductImage } from "./ProductImage";
import { Rating } from "./Rating/Rating";

interface CarouselProductCardProps {
  PriceComponent: ReactElement<PriceWithLabelsProps>;
  product: ProductDataSearch;
  productMetaData: ProductMetaData;
  selectedColumnsNumber: number;
}

export const CarouselProductCard = ({
  PriceComponent,
  product,
  productMetaData,
  selectedColumnsNumber,
}: CarouselProductCardProps) => {
  const { state } = useSearchContext();
  const { selectedFilters } = state;
  const { t } = useTranslations();
  const trackers = useTracking();
  const { showQuickshop, openQuickshop } = useQuickshop();
  const { averageRating, priceDisplay, brand, name } = product;

  if (priceDisplay === undefined) {
    throw Error("Missing price display data");
  }

  const productCode = product.code ?? "";
  const handleClick = (): void => {
    trackProductClick(trackers, product, productMetaData);
    saveCurrentScrollPosition(document.documentElement.scrollTop);
  };

  return (
    <CarouselProductCardContainer>
      <ProductLink
        href={getOptionalRelativeHref({
          product,
          selectedFilters,
        })}
        onClick={handleClick}
        data-id={productCode}
        data-testid={`carousel-product-link-${productCode}`}
      >
        <ImageContainer
          columnAmount={selectedColumnsNumber}
          isInCarousel={true}
        >
          <ProductImage
            imageUrl={product.primaryImage}
            alt={product.name}
            columnAmount={selectedColumnsNumber}
          />
          <ProductCardRibbons product={product} />
          <CardButtonIcons>
            {hasValue(product.code) && (
              <FavoriteProductHeart productStyle={product.code} />
            )}
            {showQuickshop && (
              <QuickShopButton
                onClick={(e) => openQuickshop({ event: e, productCode })}
                aria-label={t("product.details.add.to.cart.label")}
                title={t("product.details.add.to.cart.label")}
                data-testid="quickshop-button"
                className="quickshop-button"
              >
                <Cart />
              </QuickShopButton>
            )}
          </CardButtonIcons>
        </ImageContainer>
        <ProductInfoContainer className="product-info-container">
          <ProductBasicInfo
            columnAmount={selectedColumnsNumber}
            brand={brand?.name}
            name={name}
          />
          <ReviewsWrapper>
            {averageRating !== undefined && (
              <Rating averageRating={averageRating} />
            )}
          </ReviewsWrapper>
          {PriceComponent}
        </ProductInfoContainer>
      </ProductLink>
    </CarouselProductCardContainer>
  );
};
