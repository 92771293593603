import type { FunctionComponent, PropsWithChildren } from "react";
import React from "react";
import type { SearchSuggestionsProps } from "./SearchSuggestions";
import { SearchSuggestions } from "./SearchSuggestions";
import { MultiSports } from "@xxl/icons";
import { SearchQueryResetButton } from "./SearchQueryResetButton";
import { isNotNullOrUndefined } from "@xxl/common-utils";

type EmptySearchResultProps = {
  heading?: string;
  text?: string;
} & Partial<SearchSuggestionsProps>;

export const EmptySearchResult: FunctionComponent<
  PropsWithChildren<EmptySearchResultProps>
> = ({ children, heading, text, makeSense, searchSuggestions }) => (
  <>
    <div
      className="search-list__no-result-wrapper"
      data-testid="empty-result-message"
    >
      <MultiSports className="search-list__no-result-icon" />
      {heading !== "" && heading && (
        <div className="container">
          <h4 className="search-list__no-result-headline test-search__no-result-message">
            {heading}
          </h4>
          {makeSense !== undefined &&
            isNotNullOrUndefined(searchSuggestions) &&
            searchSuggestions.length > 0 && (
              <SearchSuggestions
                makeSense={makeSense}
                searchSuggestions={searchSuggestions}
              />
            )}
        </div>
      )}
      {text !== "" && text && (
        <p className="search-list__no-result-message">{text}</p>
      )}
      <SearchQueryResetButton />
    </div>

    {children}
  </>
);
