export default {
  label: {
    fontFamily: "var(--font-family-regular)",
    fontSize: 12,
    lineHeight: 1.2,
    letterSpacing: -0.15,
    laptop: {
      fontSize: 15,
    },
  },
  heading: {
    fontFamily: "var(--font-family-bold)",
    fontSize: 26,
    lineHeight: 1,
    letterSpacing: -0.15,
    laptop: {
      fontSize: 34,
    },
  },
};
