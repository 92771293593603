import { Info } from "@xxl/icons";
import React, { useEffect, useState } from "react";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { getCurrentStoreIdFromCookie } from "../../ClickAndCollect/ClickAndCollect.helper";
import {
  WarningMessageStyled,
  WarningMessageIcon,
  WarningMessageText,
} from "./AvailabilitySelector.styled";

type SelectedStoreInfoMessageProps = {
  addTopMargin?: boolean;
  isMobile: boolean;
};

const SelectedStoreInfoMessage = ({
  addTopMargin = false,
  isMobile,
}: SelectedStoreInfoMessageProps) => {
  const { t } = useTranslations();
  const [selectedCnCStoreId, setSelectedCnCStoreId] = useState<string>();

  useEffect(() => {
    setSelectedCnCStoreId(getCurrentStoreIdFromCookie());
  }, []);

  if (!isMobile) return null;
  if (selectedCnCStoreId === undefined) return null;

  return (
    <WarningMessageStyled addTopMargin={addTopMargin}>
      <WarningMessageIcon>
        <Info />
      </WarningMessageIcon>
      <WarningMessageText>
        {t("storeselect.selected.one.clickcollect.store")}
      </WarningMessageText>
    </WarningMessageStyled>
  );
};

export { SelectedStoreInfoMessage };
