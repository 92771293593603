import { ExpandIconStyled } from "./FilterBar.styled";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type FilterExpandIconProos = {
  isExpanded: boolean;
};

export const FilterExpandIcon = ({ isExpanded }: FilterExpandIconProos) => (
  <ExpandIconStyled isExpanded={isExpanded}>
    <ExpandMoreIcon width={18} />
  </ExpandIconStyled>
);
