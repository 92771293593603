import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";
import { forwardRef } from "react";
import { AVAILABILITY } from "../../../components/Search/SearchFetchProductsHelper.types";
import { getPreferredStoresCookie } from "../../../utils/Cookie";
import { mobileAndTabletNotHorizontalMediaQuery } from "../../../utils/xxl-screen";
import { FilterGridSwitch } from "../../Filter/FilterBar/FilterGridSwitch";
import { forceSolrAsProviderRegex } from "../../Search/SearchStateUrlHelper";
import {
  AvailabilityBarPlaceholder,
  AvailabilityBarStyled,
} from "../AvailabilitySelector/AvailabilityBar.styled";
import type { MultipleAvailabilitySelectorProps } from "../AvailabilitySelector/MultipleAvailabilitySelector";
import { MultipleAvailabilitySelector } from "../AvailabilitySelector/MultipleAvailabilitySelector";

type CommonProps = {
  numberOfProducts: number;
  onChange: MultipleAvailabilitySelectorProps["onChange"];
  selectedAvailability: MultipleAvailabilitySelectorProps["selectedAvailability"];
  selectedColumnsNumber: number;
  selectedStoreIds: MultipleAvailabilitySelectorProps["selectedStoreIds"];
  storesData: MultipleAvailabilitySelectorProps["storesData"];
};

export type AvailabilityBarProps =
  | (CommonProps & {
      version: 1;
      searchQuery?: string;
    })
  | (CommonProps & {
      version: 2;
    });

const AvailabilityBar = forwardRef<HTMLDivElement, AvailabilityBarProps>(
  ({ selectedColumnsNumber, ...props }, ref) => {
    const isMobileOrTablet = useMediaQuery(
      mobileAndTabletNotHorizontalMediaQuery
    );
    const {
      numberOfProducts,
      onChange,
      selectedAvailability,
      selectedStoreIds,
      storesData,
    } = props;

    if (props.version === 2) {
      // An empty array of selected stores has previously meant that all stores are selected.
      // This doesn't work for elevate since the api needs IDs of all selected stores.
      const availability =
        selectedStoreIds.length === 0
          ? [AVAILABILITY.ONLINE]
          : selectedAvailability;

      return (
        <AvailabilityBarStyled ref={ref}>
          <MultipleAvailabilitySelector
            onChange={onChange}
            selectedAvailability={availability}
            selectedStoreIds={selectedStoreIds}
            storesData={storesData}
          />
          {isMobileOrTablet ? (
            <FilterGridSwitch
              numberOfProducts={numberOfProducts}
              selectedColumnsNumber={selectedColumnsNumber}
            />
          ) : null}
        </AvailabilityBarStyled>
      );
    }

    const { searchQuery = "" } = props;
    const { ids = [] } = getPreferredStoresCookie() ?? {};
    return (
      <AvailabilityBarStyled ref={ref}>
        {!forceSolrAsProviderRegex.test(searchQuery) ? (
          <MultipleAvailabilitySelector
            onChange={onChange}
            selectedAvailability={selectedAvailability}
            selectedStoreIds={ids}
            storesData={storesData}
          />
        ) : (
          <AvailabilityBarPlaceholder />
        )}
        {isMobileOrTablet ? (
          <FilterGridSwitch
            numberOfProducts={numberOfProducts}
            selectedColumnsNumber={selectedColumnsNumber}
          />
        ) : null}
      </AvailabilityBarStyled>
    );
  }
);

AvailabilityBar.displayName = "AvailabilityBar";

export { AvailabilityBar };
