import { hasValue } from "@xxl/common-utils";
import type { BuyingGuide, Image } from "@xxl/content-api";
import type {
  CampaignPageResult,
  CategoryContentData,
  CategoryData,
  CategoryInfo,
  GuidePreviewContentData,
  GuideTagData,
  ImageData,
  LongTailData,
  ProductListingSmallBannerData,
  ShopInShopCategoryData,
} from "@xxl/frontend-api";
import type { CategoryData as BrandData } from "@xxl/pim-api";
import type { SearchResponse } from "@xxl/product-configurator-api";
import type {
  AttributeNameValuePair,
  EntitySortingParameter,
  SimpleEntityCollection,
} from "@xxl/search-api";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNumber from "lodash/isNumber";
import { createContext, useContext } from "react";
import type { XXLCookie } from "../../global";
import { getUserGroups, getUserId } from "../../utils/Cookie";
import type {
  DistinctFacet,
  DistinctFacetParameter,
  Filter,
  RangeFacet,
  RangeFacetParameter,
  RangeFacetValue,
  SearchRequestProvider,
} from "../../utils/data-types";
import type { HistoryState } from "../../utils/History";
import { windowAccess } from "../../utils/Window";
import type { CampaignData } from "../CategorySelector/Campaign/campaign-category-selector-helper";
import { findCategory } from "../CategorySelector/Campaign/campaign-category-selector-helper";
import { categoriesToSelectorCategories } from "../CategorySelector/category-selector.helper";
import type { SortData } from "../Common/Model";
import type { TabProps } from "../Common/Tabs";
import type {
  DO_FALLBACK_SEARCH,
  FINAL_SEARCH_FAILED,
  SHOW_ALL_CATEGORIES,
  TOGGLE_CATEGORY_FILTER,
} from "./Constants";
import {
  ADD_SELECTED_DISTINCT_FILTER,
  BRAND_DATA_REQUEST_SUCCESS,
  CAMPAIGN_PAGE_REQUEST_SUCCESS,
  CATEGORY_CONTENT_REQUEST_SUCCESS,
  CATEGORY_DATA_REQUEST_SUCCESS,
  CATEGORY_GUIDES_REQUEST_SUCCESS,
  CATEGORY_INFO_REQUEST_SUCCESS,
  CATEGORY_PATH_CHANGED,
  CHANGE_CATEGORY,
  CHANGE_SORT,
  COOKIE_LOAD_SUCCESS,
  FETCH_MORE_PRODUCTS,
  HISTORY_NAVIGATE_BACK,
  INITIAL_PAGE_NUMBER,
  IS_FETCHING_PRODUCTS,
  PRODUCT_LISTING_SMALL_BANNERS_REQUEST_SUCCESS,
  REMOVE_ALL_ATTRIBUTE_FILTERS,
  REMOVE_ALL_FILTERS,
  REMOVE_SELECTED_DISTINCT_FILTER,
  RESET_PAGE,
  SEARCH_REQUEST_FAILED,
  SEARCH_REQUEST_SUCCESS,
  SET_BRANDS_CONTENT,
  SET_FAQ_CONTENT,
  SET_GUIDES_CONTENT,
  SET_HIDDEN_PRODUCT_ITEMS_NUMBER,
  SET_PAGE,
  SET_SEARCH_PARAMETERS,
  SET_SEARCH_RESULT_PAGE,
  SET_SEARCH_SUGGESTIONS,
  SET_STORES_CONTENT,
  SHOW_ADDITIONAL_VIEW,
  STYLE_FACET_ATTRIBUTE_NAME,
  TABS_CHANGE,
  TABS_CREATE_SUCCESS,
  UPDATE_RANGE_FILTER,
  UPDATE_SORTS_FOR_LOGIN_STATUS,
  USER_LOGGED_IN,
} from "./Constants";
import type { LongTailTitleProps } from "./LongTailTitle";
import type { SearchProps } from "./Search";
import type { SearchResponseAugmented } from "./SearchFetchProductsHelper.types";
import {
  getAttributesForSearchRequest,
  getCampaignData,
  getInitialStateStrategy,
  getSearchProvider,
  initialStaticSearchState,
} from "./SearchInitialStateHelper";
import {
  addDistinctFilter,
  createLongTailTitle,
  getFilteredFacets,
  getSelectedFilterCount,
  getSortedFacets,
  removeDistinctFilter,
  updateRangeFilter,
} from "./SearchStateFilterHelper";
import { mapHighlightedCategoriesToCategoryData } from "./SearchStateHighlightedCategoriesHelper";
import type { QueryParameterActions } from "./SearchStateUrlHelper";
import { addLongTailPatternToCategoryUrl } from "./UrlPath";

export type ToggleFilter = {
  attributeName: string;
  selected: (string | number | boolean)[];
};
export type Facet = DistinctFacet | RangeFacet;
export type FacetList = Facet[];
export type SelectedSort = EntitySortingParameter[];

export interface HighlightedCategoryData {
  name: string;
  url: string;
  backgroundColor?: string;
  image?: ImageData;
  categoryCode: string;
}

export type State = {
  brandData: BrandData | null;
  cacheableCampaignSearchQuery: boolean;
  campaignData: null | CampaignData;
  campaignTotalProductsCount: number;
  categoryContent: CategoryContentData[] | null;
  categoryPath: string[];
  categoryInfo: CategoryInfo | null;
  expandedTopFilter: string;
  fetchMoreProductsCount: number;
  filteredFacets: FacetList;
  forceSolrAsProvider: boolean;
  guides: GuidePreviewContentData[];
  hiddenProductsNumber: number;
  initialSolrFilters: DistinctFacetParameter[];
  initialSorts: SortData[];
  isCampaignPage: boolean;
  isClearFiltersClicked: boolean;
  isFetchingCategoryData: boolean;
  isFetchingMoreProducts: boolean;
  isFetchingNewSearchResult: boolean;
  isFinalSearchFailed: boolean;
  isHistoryBackEvent: boolean;
  isInitialRenderFromServer: boolean;
  isInitialRequest: boolean;
  isSearchingAgainstFallback: boolean;
  isSearchResultPage: boolean;
  longTailTitle: LongTailTitleProps | null;
  makeSense: boolean;
  page: number;
  pageBaseUrl: string | null;
  pageSize: number;
  provider: SearchRequestProvider;
  query: string;
  queryParameterActions: QueryParameterActions;
  relativePageUrl: string | null;
  searchData: SearchResponse | SearchResponseAugmented | null;
  searchSuggestions: string[];
  selectedCategoryCode: string | null;
  selectedFilterCount: number;
  selectedFilters: Filter[];
  selectedSort: SelectedSort;
  showAllFilters: boolean;
  siteId: string;
  sorts: SortData[];
  tabs: TabProps[] | null;
  togglePlpOneCardContentComponent: boolean;
  attribute?: AttributeNameValuePair;
  brandName?: string;
  brandCode?: string;
  brandsTabContent?: BrandsDataProps[];
  campaignId?: string;
  categoryId?: string;
  faqTabContent?: FaqDataProps[];
  guidesTabContent?: GuidePreviewContentData[];
  hideHeader?: boolean;
  highlightedCategories?: HighlightedCategoryData[];
  longTailFacets?: LongTailData[];
  longTailPattern?: string;
  productListingSmallBanner?: ProductListingSmallBannerData[] | null;
  shopInShopCategoryData?: ShopInShopCategoryData | null;
  storesTabContent?: StoresDataProps[];
  userGroups?: string;
  userId?: string;
  showAdditionalView?: boolean;
  forceShowAll?: boolean;
  isBot: boolean;
  additionalCampaignIds?: string[];
  buyingGuideData: BuyingGuide | null;
};

export type StateStruct = {
  current: State;
  prev?: State;
};

export type ChangeSort = {
  categoryId: string;
};

export type UpdateRangeFilterPayload = {
  attributeName: string;
  range: RangeFacetValue;
};

export type BrandsDataProps = {
  id: string;
  name: string | boolean;
  code: string | boolean;
  logo: Image | boolean;
  brandUrl?: string;
  ticket?: string;
};

export type OpeningHours = {
  closed: boolean;
  closesAt: string | null;
  day: string;
  opensAt: string | null;
};

export type StoresDataProps = {
  id: string;
  name: string | boolean;
  openingHours: OpeningHours[];
  link?: string;
  ticket?: string;
};

export type FaqDataProps = {
  id: string;
  title: string;
  url: string;
  preamble?: string;
  ticket?: string;
};

export type Action =
  | { type: typeof COOKIE_LOAD_SUCCESS; payload: XXLCookie }
  | {
      type: typeof SEARCH_REQUEST_SUCCESS;
      payload: SearchResponse | SearchResponseAugmented;
    }
  | { type: typeof SEARCH_REQUEST_FAILED }
  | { type: typeof FETCH_MORE_PRODUCTS }
  | { type: typeof SET_PAGE; payload: number }
  | { type: typeof CHANGE_SORT; payload: EntitySortingParameter }
  | { type: typeof UPDATE_SORTS_FOR_LOGIN_STATUS; payload: SortData[] }
  | { type: typeof DO_FALLBACK_SEARCH }
  | { type: typeof FINAL_SEARCH_FAILED }
  | { type: typeof ADD_SELECTED_DISTINCT_FILTER; payload: ToggleFilter }
  | { type: typeof REMOVE_SELECTED_DISTINCT_FILTER; payload: ToggleFilter }
  | { type: typeof RESET_PAGE }
  | { type: typeof SET_HIDDEN_PRODUCT_ITEMS_NUMBER; payload: number }
  | {
      type: typeof SET_SEARCH_PARAMETERS;
      payload: {
        categoryPath: string[];
        selectedSort: SelectedSort;
        categoryCode: string | null;
        filters?: Filter[];
      };
    }
  | {
      type: typeof TOGGLE_CATEGORY_FILTER;
      payload: { categoryCode: string | null };
    }
  | { type: typeof REMOVE_ALL_FILTERS }
  | { type: typeof REMOVE_ALL_ATTRIBUTE_FILTERS; payload: ToggleFilter }
  | { type: typeof CAMPAIGN_PAGE_REQUEST_SUCCESS; payload: CampaignPageResult }
  | {
      type: typeof USER_LOGGED_IN;
      payload: { userId: string; userGroups: string };
    }
  | {
      type: typeof CATEGORY_CONTENT_REQUEST_SUCCESS;
      payload: CategoryContentData[];
    }
  | {
      type: typeof BRAND_DATA_REQUEST_SUCCESS;
      payload: BrandData;
    }
  | { type: typeof CATEGORY_INFO_REQUEST_SUCCESS; payload: CategoryInfo }
  | {
      type: typeof CATEGORY_DATA_REQUEST_SUCCESS;
      payload: ShopInShopCategoryData | undefined;
    }
  | { type: typeof SHOW_ALL_CATEGORIES }
  | { type: typeof CHANGE_CATEGORY; payload: ChangeSort }
  | { type: typeof HISTORY_NAVIGATE_BACK; payload: HistoryState }
  | { type: typeof IS_FETCHING_PRODUCTS }
  | {
      type: typeof PRODUCT_LISTING_SMALL_BANNERS_REQUEST_SUCCESS;
      payload: ProductListingSmallBannerData[];
    }
  | {
      type: typeof CATEGORY_GUIDES_REQUEST_SUCCESS;
      payload: GuidePreviewContentData[];
    }
  | { type: typeof UPDATE_RANGE_FILTER; payload: UpdateRangeFilterPayload }
  | { type: typeof TABS_CREATE_SUCCESS; payload: TabProps[] }
  | { type: typeof TABS_CHANGE; payload: string }
  | { type: typeof SET_SEARCH_RESULT_PAGE; payload: boolean }
  | {
      type: typeof SET_BRANDS_CONTENT;
      payload: SimpleEntityCollection;
    }
  | {
      type: typeof SET_GUIDES_CONTENT;
      payload: SimpleEntityCollection;
    }
  | {
      type: typeof SET_STORES_CONTENT;
      payload: { stores: SimpleEntityCollection };
    }
  | {
      type: typeof SET_SEARCH_SUGGESTIONS;
      payload: {
        searchSuggestions: string[];
        makeSense: boolean;
      };
    }
  | {
      type: typeof SET_FAQ_CONTENT;
      payload: SimpleEntityCollection;
    }
  | {
      type: typeof CATEGORY_PATH_CHANGED;
      payload: string[];
    }
  | {
      type: typeof SHOW_ADDITIONAL_VIEW;
      payload: boolean;
    };

export const getInitialState = (
  initialSearchStateData: SearchProps
): StateStruct => {
  const state = {
    current: getInitialStateStrategy(
      initialSearchStateData
    ).getInitialSearchState(initialSearchStateData),
  };
  return state;
};

export const canDoFallbackSearch = (state: State): boolean =>
  !hasValue(state.campaignId) && !state.isSearchingAgainstFallback;

export const getSelectedCampaignCategoryName = (
  categories: CategoryData[],
  categoryPath: string[]
): string | null => {
  try {
    const campaignCategories = categoriesToSelectorCategories(categories);
    const category = findCategory(campaignCategories, categoryPath);
    return category.name;
  } catch (_e) {
    return null;
  }
};

export const getFetchMoreProductsCount = (
  totalNumberOfProducts: number,
  page: number,
  pageSize: number
): number => {
  const NO_PRODUCTS_COUNT = 0;
  // Initial page is 0 so we need to add 1
  const pagePlusOne = page + 1;
  const fetchMoreProductsCount = totalNumberOfProducts - pagePlusOne * pageSize;
  return Math.max(fetchMoreProductsCount, NO_PRODUCTS_COUNT);
};

export const getUpdatedStateOnSearchSuccess = (
  state: State,
  response: SearchResponse | SearchResponseAugmented
) => {
  const { facets: facetsFromServer = [] } = response.results ?? {};
  const facetsFromServerWithMappedColorValue = facetsFromServer.map((facet) => {
    if (
      facet.attributeName === STYLE_FACET_ATTRIBUTE_NAME &&
      "items" in facet
    ) {
      const items = facet.items?.map((item) => {
        if ("colors" in facet) {
          const { item: value } = item;
          if (typeof value !== "string") {
            return item;
          }
          const color =
            (facet.colors as Record<string, string | null>)[value] ?? undefined;
          return {
            ...item,
            color,
          };
        }
        return item;
      });
      return {
        ...facet,
        items,
      };
    }
    return facet;
  });
  const selectedFilters = state.isInitialRequest
    ? state.selectedFilters.filter(
        (f: DistinctFacetParameter | RangeFacetParameter) =>
          response.results?.acceptedFacets?.includes(f.attributeName) ?? false
      )
    : state.selectedFilters;
  const EMPTY_RESULTS_NUMBER = 0;
  const totalNumberOfProducts = response.results?.count ?? EMPTY_RESULTS_NUMBER;
  const fetchMoreProductsCount = getFetchMoreProductsCount(
    totalNumberOfProducts,
    state.page,
    state.pageSize
  );
  const facets =
    facetsFromServerWithMappedColorValue.length > 0
      ? facetsFromServerWithMappedColorValue
      : state.filteredFacets;
  const { categoryLevel, subCategories } = state.categoryInfo?.category ?? {};
  const { isCampaignPage } = state;
  const filteredFacets = getSortedFacets(
    getFilteredFacets({
      categoryLevel,
      facets,
      isCampaignPage,
      subCategories,
    })
  );
  const commonState: State = {
    ...state,
    ...{
      filteredFacets,
      fetchMoreProductsCount,
      isFetchingMoreProducts: false,
      isFetchingCategoryData: false,
      isFetchingNewSearchResult: state.isInitialRenderFromServer, // We will do additional requests client side, keep loading state
      isInitialRenderFromServer: false,
      isInitialRequest: state.isInitialRenderFromServer, // We will re-fetch products client side, keep isInitialRequest flag
      isClearFiltersClicked: false,
      selectedFilters: isEqual(state.selectedFilters, selectedFilters)
        ? state.selectedFilters
        : selectedFilters,
      selectedFilterCount: getSelectedFilterCount(selectedFilters),
    },
  };
  const shouldAmendResult =
    !state.isInitialRequest && state.page > INITIAL_PAGE_NUMBER;
  if (shouldAmendResult && state.searchData !== null) {
    return {
      ...commonState,
      ...{
        searchData: {
          ...state.searchData,
          ...{
            results: {
              ...state.searchData.results,
              items: [...(response.results?.items ?? [])],
            },
          },
        },
      },
    };
  }
  return { ...commonState, ...{ searchData: response } };
};

const reducerInternal = (state: State, action: Action): State => {
  const sharedFetchNewProductsFromStartState = {
    page: INITIAL_PAGE_NUMBER,
  };

  switch (action.type) {
    case COOKIE_LOAD_SUCCESS:
      return {
        ...state,
        ...{
          userGroups:
            getUserGroups(action.payload) !== ""
              ? getUserGroups(action.payload)
              : undefined,
          userId: getUserId(action.payload),
        },
      };
    case UPDATE_SORTS_FOR_LOGIN_STATUS: {
      if (isEqual(state.sorts, action.payload)) {
        return state;
      }
      return { ...state, ...{ sorts: action.payload } };
    }

    case SEARCH_REQUEST_SUCCESS: {
      return getUpdatedStateOnSearchSuccess(state, action.payload);
    }
    case SET_HIDDEN_PRODUCT_ITEMS_NUMBER:
      return {
        ...state,
        hiddenProductsNumber: action.payload,
      };
    case FETCH_MORE_PRODUCTS:
      return {
        ...state,
        ...{
          isFetchingMoreProducts: true,
          page: state.page + 1,
        },
      };
    case SET_PAGE:
      return {
        ...state,
        ...{
          isFetchingMoreProducts: true,
          page: action.payload,
        },
      };
    case CHANGE_SORT:
      return {
        ...state,
        ...sharedFetchNewProductsFromStartState,
        selectedSort: [action.payload],
      };
    case ADD_SELECTED_DISTINCT_FILTER: {
      const addedFilters = addDistinctFilter(
        state.selectedFilters,
        action.payload
      );

      return {
        ...state,
        ...sharedFetchNewProductsFromStartState,
        selectedFilters: addedFilters,
        selectedFilterCount: getSelectedFilterCount(addedFilters),
        longTailTitle: createLongTailTitle(
          addedFilters,
          state.longTailFacets,
          state.categoryInfo?.category,
          state.longTailTitle
        ),
      };
    }
    case UPDATE_RANGE_FILTER: {
      const selectedFilters = updateRangeFilter(
        state.selectedFilters,
        action.payload
      );

      return {
        ...state,
        ...sharedFetchNewProductsFromStartState,
        selectedFilters,
        selectedFilterCount: getSelectedFilterCount(selectedFilters),
      };
    }
    case SET_SEARCH_PARAMETERS: {
      const { categoryCode, categoryPath } = action.payload;
      return {
        ...state,
        ...sharedFetchNewProductsFromStartState,
        ...{
          selectedCategoryCode: categoryCode,
          categoryPath,
        },
        ...(action.payload.filters !== undefined && {
          selectedFilters: action.payload.filters,
          selectedFilterCount: getSelectedFilterCount(state.selectedFilters),
        }),
        ...(action.payload.selectedSort.length !== 0 && {
          selectedSort: action.payload.selectedSort,
        }),
      };
    }
    case REMOVE_SELECTED_DISTINCT_FILTER: {
      const removedFilters = removeDistinctFilter(
        state.selectedFilters,
        action.payload
      );

      return {
        ...state,
        ...sharedFetchNewProductsFromStartState,
        selectedFilters: removedFilters,
        selectedFilterCount: getSelectedFilterCount(removedFilters),
        longTailTitle: createLongTailTitle(
          removedFilters,
          state.longTailFacets,
          state.categoryInfo?.category,
          state.longTailTitle
        ),
      };
    }
    case REMOVE_ALL_FILTERS:
      return {
        ...state,
        ...sharedFetchNewProductsFromStartState,
        selectedFilters: isEmpty(state.selectedFilters) // Prevent React hook from triggering by returning same array
          ? state.selectedFilters
          : [],
        selectedFilterCount: getSelectedFilterCount(state.selectedFilters),
        isClearFiltersClicked: true,
        longTailTitle: null,
      };
    case REMOVE_ALL_ATTRIBUTE_FILTERS:
      return {
        ...state,
        ...sharedFetchNewProductsFromStartState,
        selectedFilters: removeDistinctFilter(
          state.selectedFilters,
          action.payload
        ),
      };
    case RESET_PAGE:
      return {
        ...state,
        ...sharedFetchNewProductsFromStartState,
      };

    case SEARCH_REQUEST_FAILED: {
      const { brandCode, categoryId, additionalCampaignIds } = state;
      const isSolrProvider = true;
      const provider = getSearchProvider(isSolrProvider);
      return {
        ...state,
        ...(canDoFallbackSearch(state)
          ? {
              attribute: getAttributesForSearchRequest({
                brandCode,
                categoryId,
                isSolrProvider,
                additionalCampaignIds,
              }),
              isInitialRequest: true,
              isSearchingAgainstFallback: true,
              provider,
            }
          : {
              isFinalSearchFailed: true,
            }),
      };
    }
    case CAMPAIGN_PAGE_REQUEST_SUCCESS: {
      const { campaignPageContent, categories } = action.payload;
      const campaignData = getCampaignData(campaignPageContent, categories);

      return {
        ...state,
        ...{
          campaignData,
          campaignTotalProductsCount:
            campaignPageContent?.totalProductsCount ?? 0,
        },
      };
    }
    case USER_LOGGED_IN:
      return {
        ...state,
        ...{
          isInitialRequest: true,
          searchData: null,
          userGroups: action.payload.userGroups,
          userId: action.payload.userId,
        },
      };
    case CATEGORY_CONTENT_REQUEST_SUCCESS:
      return {
        ...state,
        ...{
          categoryContent: action.payload,
        },
      };
    case CATEGORY_GUIDES_REQUEST_SUCCESS:
      return {
        ...state,
        ...{
          guides: action.payload,
        },
      };
    case PRODUCT_LISTING_SMALL_BANNERS_REQUEST_SUCCESS:
      return {
        ...state,
        productListingSmallBanner: action.payload,
      };
    case CATEGORY_INFO_REQUEST_SUCCESS: {
      const category = action.payload.category ?? {};
      const { categoryLevel, subCategories, url } = category;
      const longTailPattern = addLongTailPatternToCategoryUrl(url ?? "");
      const { isCampaignPage } = state;
      let filteredFacets = getFilteredFacets({
        categoryLevel,
        facets: state.filteredFacets,
        isCampaignPage,
        subCategories,
      });

      filteredFacets = getSortedFacets(filteredFacets);

      return {
        ...state,
        ...{
          categoryInfo: action.payload,
          filteredFacets,
          isFetchingCategoryData: false,
          isHistoryBackEvent: false,
          longTailPattern,
          longTailTitle: createLongTailTitle(
            state.selectedFilters,
            state.longTailFacets,
            action.payload.category,
            state.longTailTitle
          ),
        },
      };
    }
    case CATEGORY_DATA_REQUEST_SUCCESS: {
      const { subCategories = [] } = state.categoryInfo?.category ?? {};
      const { highlightedCategories = [] } = action.payload ?? {};

      const mappedHighlightedCategories =
        mapHighlightedCategoriesToCategoryData({
          categories: subCategories,
          highlightedCategories,
        });

      return {
        ...state,
        ...{
          shopInShopCategoryData: action.payload,
          highlightedCategories: mappedHighlightedCategories,
        },
      };
    }
    case CHANGE_CATEGORY: {
      const categoryId = action.payload.categoryId;
      return {
        ...state,
        ...sharedFetchNewProductsFromStartState,
        ...{
          attribute: getAttributesForSearchRequest({ categoryId }),
          categoryId: action.payload.categoryId,
          isFetchingCategoryData: true,
          selectedFilters: [],
        },
      };
    }
    case HISTORY_NAVIGATE_BACK: {
      const { categoryId, page, selectedFilters, selectedSort } =
        action.payload;
      if (categoryId === state.categoryId) {
        return state;
      }
      return {
        ...state,
        ...{
          attribute: getAttributesForSearchRequest({ categoryId }),
          categoryId,
          isFetchingCategoryData: true,
          isHistoryBackEvent: true,
          isInitialRequest: true,
          page: isNumber(page) ? page : INITIAL_PAGE_NUMBER,
          selectedFilters: selectedFilters ?? state.selectedFilters,
          selectedSort: selectedSort ?? state.selectedSort,
        },
      };
    }
    case BRAND_DATA_REQUEST_SUCCESS: {
      const { longTailTitle } = state;
      if (longTailTitle !== null && longTailTitle.brand !== null) {
        longTailTitle.brand.url = action.payload.url;
      }
      return {
        ...state,
        ...{
          brandData: action.payload,
          longTailTitle,
        },
      };
    }
    case IS_FETCHING_PRODUCTS:
      return {
        ...state,
        isFetchingNewSearchResult: true,
      };
    case TABS_CREATE_SUCCESS:
      return {
        ...state,
        tabs: action.payload,
      };
    case TABS_CHANGE: {
      const changedTabs: TabProps[] = [];
      state.tabs?.forEach((item) => {
        changedTabs.push({
          ...item,
          isActive: item.id === action.payload,
        });
      });
      return {
        ...state,
        tabs: changedTabs,
      };
    }
    case SET_SEARCH_RESULT_PAGE:
      return {
        ...state,
        isSearchResultPage: action.payload,
      };
    case SET_BRANDS_CONTENT: {
      const data = action.payload;
      const brands: BrandsDataProps[] = [];
      if (data.items !== undefined) {
        data.items.forEach((item) => {
          const itemName = item.attributes?.find(
            (i) => i.name === "brand_brandName"
          );
          const itemCode = item.attributes?.find(
            (i) => i.name === "brand_brandCode"
          );
          const itemLogo = item.attributes?.find(
            (i) => i.name === "brand_logo"
          );
          let logoObject: Image = {};
          if (
            itemLogo !== undefined &&
            itemLogo.values !== undefined &&
            itemLogo.values.length > 0 &&
            (itemLogo.values[0] as string).indexOf("{") === 0
          ) {
            logoObject = JSON.parse(itemLogo.values[0] as string) as Image;
          } else if (
            itemLogo !== undefined &&
            itemLogo.values !== undefined &&
            itemLogo.values.length > 1
          ) {
            const logoJson = `{${itemLogo.values.join(",")}}`;
            logoObject = JSON.parse(logoJson) as Image;
          }
          const brandObject = {
            id: item.id,
            name:
              itemName !== undefined &&
              itemName.values !== undefined &&
              (itemName.values[0] as string),
            code:
              itemCode !== undefined &&
              itemCode.values !== undefined &&
              (itemCode.values[0] as string),
            logo: logoObject,
          };
          brands.push(brandObject);
        });
      }

      return {
        ...state,
        brandsTabContent: brands,
      };
    }
    case SET_GUIDES_CONTENT: {
      const data = action.payload;
      const guides: GuidePreviewContentData[] = [];
      const getTags = (tags: string[]): GuideTagData[] =>
        tags.map((i) => JSON.parse(i) as GuideTagData);
      if (data.items !== undefined) {
        data.items.forEach((item) => {
          const itemTitle = item.attributes?.find(
            (i) => i.name === "guide_title"
          );
          const itemImageUrl = item.attributes?.find(
            (i) => i.name === "guide_image_url"
          );
          const imageObject = {
            baseUrl:
              itemImageUrl !== undefined && itemImageUrl.values !== undefined
                ? (itemImageUrl.values[0] as string)
                : "",
            alt:
              itemTitle !== undefined && itemTitle.values !== undefined
                ? (itemTitle.values[0] as string)
                : "",
          };
          const itemUrl = item.attributes?.find((i) => i.name === "guide_url");
          const itemPreamble = item.attributes?.find(
            (i) => i.name === "guide_preamble"
          );
          const itemTags = item.attributes?.find(
            (i) => i.name === "guide_tags"
          );
          const tagsArray =
            itemTags !== undefined &&
            itemTags.values !== undefined &&
            itemTags.values.length > 0
              ? getTags(itemTags.values as string[])
              : [];
          const itemPublicationDate = item.attributes?.find(
            (i) => i.name === "guide_publicationDate"
          );

          const guideObject = {
            title:
              itemTitle !== undefined && itemTitle.values !== undefined
                ? (itemTitle.values[0] as string)
                : "",
            image: imageObject,
            categorizedUrl: "",
            url:
              itemUrl !== undefined && itemUrl.values !== undefined
                ? (itemUrl.values[0] as string)
                : "",
            preamble:
              itemPreamble !== undefined && itemPreamble.values !== undefined
                ? (itemPreamble.values[0] as string)
                : "",
            tags: tagsArray,
            publicationDate:
              itemPublicationDate !== undefined &&
              itemPublicationDate.values !== undefined
                ? (itemPublicationDate.values[0] as string)
                : "",
            canonicalUrl: "",
          };

          guides.push(guideObject);
        });
      }
      guides.sort((a, b): number => {
        const aDate =
          a.publicationDate !== undefined ? Date.parse(a.publicationDate) : 0;
        const bDate =
          b.publicationDate !== undefined ? Date.parse(b.publicationDate) : 0;
        if (aDate > bDate) {
          return -1;
        } else if (aDate < bDate) {
          return 1;
        }
        return 0;
      });
      return {
        ...state,
        guidesTabContent: guides,
      };
    }

    case SET_STORES_CONTENT: {
      const { stores: data } = action.payload;
      const stores: StoresDataProps[] = [];
      if (data.items !== undefined) {
        data.items.forEach((item) => {
          const itemName = item.attributes?.find(
            (i) => i.name === "store_name"
          );

          const itemOpeningHours = item.attributes?.find(
            (i) => i.name === "store_openingHours"
          );
          const hoursArr: OpeningHours[] = [];
          if (
            itemOpeningHours !== undefined &&
            itemOpeningHours.values !== undefined &&
            itemOpeningHours.values.length > 0
          ) {
            itemOpeningHours.values.forEach((day) => {
              hoursArr.push(JSON.parse(day as string) as OpeningHours);
            });
          }
          const storeObject = {
            id: item.id,
            name:
              itemName !== undefined && itemName.values !== undefined
                ? (itemName.values[0] as string)
                : "",
            openingHours: hoursArr,
          };
          stores.push(storeObject);
        });
      }

      return {
        ...state,
        storesTabContent: stores,
      };
    }
    case SET_FAQ_CONTENT: {
      const data = action.payload;
      const faq: FaqDataProps[] = [];
      const { requestMapping } = windowAccess()._sharedData;
      const { customerService: customerServiceHubPath, faq: faqBasePath } =
        requestMapping;

      if (data.items !== undefined) {
        data.items.forEach((item) => {
          const itemTitle = item.attributes?.find(
            (i) =>
              i.name === "faqEntry_title" ||
              i.name ===
                "customerServiceQuestionAndAnswer_customerServiceQuestion"
          );
          const categoryUrl = item.attributes?.find(
            (i) => i.name === "customerServiceQuestionAndAnswer_categoryUrl"
          );
          const itemHash = item.attributes?.find(
            (i) => i.name === "customerServiceQuestionAndAnswer_fragmentId"
          );
          const itemUrl = item.attributes?.find(
            (i) => i.name === "faqEntry_url"
          );
          const itemPreamble = item.attributes?.find(
            (i) => i.name === "faqEntry_preamble"
          );
          let url = "";
          if (
            itemUrl === undefined &&
            categoryUrl !== undefined &&
            itemHash !== undefined &&
            categoryUrl.values !== undefined &&
            itemHash.values !== undefined
          ) {
            url = `${customerServiceHubPath}/${
              categoryUrl.values[0] as string
            }#${itemHash.values[0] as string}`;
          } else if (itemUrl !== undefined && itemUrl.values !== undefined) {
            url = `${faqBasePath}/${itemUrl.values[0] as string}`;
          }
          const faqObject = {
            id: item.id,
            title:
              itemTitle !== undefined && itemTitle.values !== undefined
                ? (itemTitle.values[0] as string)
                : "",
            url,
            preamble:
              itemPreamble !== undefined && itemPreamble.values !== undefined
                ? (itemPreamble.values[0] as string)
                : "",
          };

          faq.push(faqObject);
        });
      }

      return {
        ...state,
        faqTabContent: faq,
      };
    }
    case SET_SEARCH_SUGGESTIONS: {
      const { makeSense, searchSuggestions } = action.payload;
      return {
        ...state,
        makeSense: makeSense,
        searchSuggestions: searchSuggestions,
      };
    }
    case CATEGORY_PATH_CHANGED: {
      const categoryPath = action.payload;
      const selectedCategoryCode = [...categoryPath].pop();

      return {
        ...state,
        ...sharedFetchNewProductsFromStartState,
        categoryPath:
          categoryPath.length === 0 || selectedCategoryCode === undefined
            ? []
            : categoryPath,
        showAdditionalView: categoryPath.length === 0,
      };
    }
    case SHOW_ADDITIONAL_VIEW: {
      return {
        ...state,
        showAdditionalView: action.payload,
      };
    }
    default:
      return state;
  }
};

export const reducer = (
  stateStruct: StateStruct,
  action: Action
): StateStruct => {
  return {
    prev: stateStruct.current,
    current: reducerInternal(stateStruct.current, action),
  };
};

export type SearchStateDispatch = (action: Action) => void;
type DefaultValue = {
  state: State;
  dispatch: SearchStateDispatch;
};

export const SearchContext = createContext<DefaultValue>({
  dispatch: (): void => {
    return;
  },
  state: initialStaticSearchState,
});

export const useSearchContext = (): DefaultValue => useContext(SearchContext);
