import isNaN from "lodash/isNaN";
import isNumber from "lodash/isNumber";
import { useEffect, useState } from "react";
import { useXxlMediaQuery } from "react-app/src/hooks/useXxlMediaQuery";
import { parseNumber } from "react-app/src/utils/xxl-number";
import type { XxlSetSelectedColumnsNumberEventPayload } from "react-app/src/global";
import {
  addXXLEventListener,
  removeXXLEventListener,
} from "react-app/src/utils/xxl-event";
import {
  setStorageItem,
  storageKeys,
} from "react-app/src/utils/LocalStorage/local-storage";
import { EMPTY_STRING } from "react-app/src/constants";
import type { NumberOfColumnsPerDeviceOnPlp } from "react-app/src/components/ProductList/hooks/useSelectedColumnsNumber/useSelectedColumnsNumber.types";
import {
  isFunctionalCookieEnabled,
  setCookie,
} from "react-app/src/utils/Cookie";
import { hasValue, isNotNullOrUndefined } from "@xxl/common-utils";

const DEFAULT_VALUE_PER_DEVICE = {
  mobile: 2,
  tablet: 4,
  desktop: 6,
};

const STORAGE_KEYS = {
  mobile: storageKeys.PREFERRED_PLP_COLUMNS_NUMBER_MOBILE,
  tablet: storageKeys.PREFERRED_PLP_COLUMNS_NUMBER_TABLET,
  desktop: storageKeys.PREFERRED_PLP_COLUMNS_NUMBER_DESKTOP,
};

const getDeviceType = ({
  isMobile,
  isTablet,
}: {
  isMobile: boolean;
  isTablet: boolean;
}) => (isMobile ? "mobile" : isTablet ? "tablet" : "desktop");

const useSelectedColumnsNumber = ({
  initialNumberOfColumnsPerDeviceOnPlp,
}: {
  initialNumberOfColumnsPerDeviceOnPlp: NumberOfColumnsPerDeviceOnPlp | null;
}) => {
  const isMobile = useXxlMediaQuery("MobileMediaQuery");
  const isTablet = useXxlMediaQuery("MobileAndTabletNotHorizontalMediaQuery");
  const initialSelectedColumnNumbers = isNotNullOrUndefined(
    initialNumberOfColumnsPerDeviceOnPlp
  )
    ? initialNumberOfColumnsPerDeviceOnPlp
    : DEFAULT_VALUE_PER_DEVICE;
  const [selectedColumnNumbers, setSelectedColumnNumbers] = useState(
    initialSelectedColumnNumbers
  );

  /** Get value from local storage when missing initial value */
  useEffect(() => {
    if (hasValue(initialNumberOfColumnsPerDeviceOnPlp)) {
      return;
    }
    const key = getDeviceType({ isMobile, isTablet });
    const keyForLocalStorage = STORAGE_KEYS[key];
    const valueFromStorage =
      localStorage.getItem(keyForLocalStorage) ?? EMPTY_STRING;
    const parsedValue = parseNumber(valueFromStorage);
    if (!isNumber(parsedValue) || isNaN(parsedValue)) {
      return;
    }
    setSelectedColumnNumbers((state) => ({
      ...state,
      [key]: parsedValue,
    }));
  }, [initialNumberOfColumnsPerDeviceOnPlp, isMobile, isTablet]);

  /** Listen for changes */
  useEffect(() => {
    const callback = ({
      detail,
    }: CustomEvent<XxlSetSelectedColumnsNumberEventPayload>) => {
      const value = detail.selectedColumnsNumber;
      const keyForCookie = getDeviceType({ isMobile, isTablet });
      const newValue = {
        ...selectedColumnNumbers,
        [keyForCookie]: value,
      };
      setSelectedColumnNumbers(newValue);
      const keyForLocalStorage =
        STORAGE_KEYS[getDeviceType({ isMobile, isTablet })];
      setStorageItem(keyForLocalStorage, value.toString());
      if (isFunctionalCookieEnabled()) {
        setCookie(
          "xxl-number-of-columns-per-device-on-plp",
          JSON.stringify(newValue)
        );
        return;
      }
    };
    addXXLEventListener("XXL_SET_SELECTED_COLUMNS_NUMBER", callback);
    return () =>
      removeXXLEventListener("XXL_SET_SELECTED_COLUMNS_NUMBER", callback);
  }, [isMobile, isTablet, selectedColumnNumbers]);

  return selectedColumnNumbers[getDeviceType({ isMobile, isTablet })];
};

export { useSelectedColumnsNumber };
