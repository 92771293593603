import type { RangeFacetSelectedParameter } from "@xxl/search-api";
import type { State } from "../Search/SearchState";
import { getSelectedCampaignCategoryName } from "../Search/SearchState";
import type { Trackers } from "../../contexts/Tracking";

type TrackFilterChangeProps = {
  attributeName?: string;
  category?: string;
  isFilterActive: boolean;
  items?: (
    | string
    | number
    | boolean
    | RangeFacetSelectedParameter
    | undefined
  )[];
  searchQuery?: string;
  trackers: Trackers;
};

export const trackFilterChange = ({
  attributeName,
  category,
  isFilterActive,
  items,
  searchQuery,
  trackers,
}: TrackFilterChangeProps): void => {
  const filters = (items ?? []).map((item = "") => ({
    name: attributeName ?? "",
    value: item,
    ...(category !== undefined && { category }),
    ...(searchQuery !== undefined && { searchQuery }),
  }));
  trackers.sendSelectFilterEvent({ isFilterActive, filters });
};

export const getCategoryNameForTracking = (state: State): string => {
  const campaignCategoryName = getSelectedCampaignCategoryName(
    state.campaignData?.categories ?? [],
    state.categoryPath
  );
  const categoryName = state.categoryInfo?.category?.name;

  return categoryName ?? campaignCategoryName ?? "";
};
